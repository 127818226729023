import { ExternalLinks } from '_constants/externalLinks/type'

const faqDomainUrl = 'https://aide.blank.app'

export const BLANK_EXTERNAL_LINKS_FR: ExternalLinks = {
  cguLink: 'https://www.blank.app/documents-legaux/cgu-Plateforme-de-Gestion',
  bankingOrganizationPrivacyPolicyLink:
    'https://www.blank.app/documents-legaux/politique-protection-des-donnees',
  brandPrivacyPolicyLink: '',
  appStoreLink:
    'https://apps.apple.com/fr/app/blank-lapp-des-ind%C3%A9pendants/id1522046776',
  googlePlayLink:
    'https://play.google.com/store/apps/details?id=app.blank.mobile&hl=fr',
  faq: faqDomainUrl,
  mandateSepa: `${faqDomainUrl}/hc/fr/articles/4433238933905`,
  invoicingFaq: `${faqDomainUrl}/hc/fr/sections/6018171570193`,
  requestHelp: `${faqDomainUrl}/hc/fr/requests/new`,
  rcProInfo:
    'https://www.blank.app/assurance-professionnelle/responsabilite-civile-professionnelle',
  decadeGuaranteeInfo:
    'https://www.blank.app/assurance-professionnelle/assurance-decennale',
  multiriskInfo:
    'https://www.blank.app/assurance-professionnelle/assurance-multirisque-professionnelle',
  legalPlaceCompany: 'https://heyflow.id/blank-creation-corpo',
  helpNonDiffusible: `${faqDomainUrl}/kb/guide/fr/quelles-sont-les-conditions-pour-ouvrir-un-compte-LyqXxflXD5/Steps/2677244,2677254`,
  helpNonEligible: `${faqDomainUrl}/hc/fr/articles/4433239644177`,
  helpBeneficialOwners: `${faqDomainUrl}/hc/fr/articles/4433243620369`,
  urssaf: {
    portal: 'http://autoentrepreneur.urssaf.fr/',
    microEntrepreneurs:
      'https://www.autoentrepreneur.urssaf.fr/portail/accueil/sinformer-sur-le-statut/lessentiel-du-statut.html',
    socialContributions: 'http://www.aquoiserventlescotisations.urssaf.fr/',
    sepaMandate: 'https://www.blank.app/compte-professionnel/mandat-sepa',
    faq: `${faqDomainUrl}/hc/fr/sections/6018170469137`,
    tdaeStatusHelp: `${faqDomainUrl}/hc/fr/articles/4433228060049`,
    tdaePaymentAuthorization: `${faqDomainUrl}/hc/fr/articles/4433237466257`,
    tou: 'https://www.blank.app/documents-legaux/cgu-tierce-declaration-urssaf',
  },
  faqWhoIsBlankFor: `${faqDomainUrl}/hc/fr/articles/6835891456785`,
  psdFAQ: `${faqDomainUrl}/hc/fr/articles/8095900392721`,
  legalLink: 'https://www.blank.app/documents-legaux',
  supportEmail: 'support@blank.app',
  securityEmail: 'securite@blank.app',
  leadEmail: '',
  sepaTransfer: `${faqDomainUrl}/hc/fr/articles/5846985316753`,
  compareOffers: 'https://www.blank.app/tarifs',
  touBankAccount:
    'https://www.blank.app/documents-legaux/cgu-compte-de-paiement',
  kycHelp: `${faqDomainUrl}/hc/fr/articles/7658164435985`,
  legalStatus: 'https://www.blank.app/creer-son-entreprise/statut-juridique',
  capitalDeposit: 'https://www.blank.app/depot-de-capital',
  discoverManagementToolsOffer: '',
  sponsorshipFAQ: `${faqDomainUrl}/kb/guide/fr/comment-inviter-mes-proches-independants-a-ouvrir-un-compte-professionnel-chez-blank-y1efgHa5qI/Steps/2969741`,
  priceBrochure:
    'https://assets.website-files.com/5e2f01f286f417166130daf6/63369e10f798ee4ae0d25065_Plaquettetarifaire_Blank_compressed-30%3A09%3A22.pdf',
  changeNonDiffusibleCompany: 'https://statut-diffusion-sirene.insee.fr',
  sponsorshipInvitationLink: 'https://blank.app/offre-parrainage',
  transparencyPolicy: '',
  requestDeviceResetFAQ:
    'https://aide.blank.app/kb/guide/fr/comment-est-securise-mon-compte-appareil-de-reference-sca-code-de-securite-etc-oxriy1ABpg/Steps/2599508',
  accountClosingFaq:
    'https://aide.blank.app/kb/guide/fr/puis-je-recuperer-mon-compte-si-celui-ci-est-cloture-4fcqxo2NBW/Steps/2951891',
  bankScamPreventionLink: '',
}
