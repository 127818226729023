import { EnumAccountSource } from '@blank/api'
import { useCallback } from 'react'
import { useAccountQuery } from '../../queries/useAccountQuery'
import { useAccountsQuery } from '../../queries/useAccountsQuery'

interface Props {
  enabled?: boolean
}

export const useInternalAccount = ({ enabled }: Props = {}) => {
  const {
    data,
    isLoading: isAccountsLoading,
    isError: isAccountsError,
    refetch: refetchAccounts,
    isSuccess: isAccountsSuccess,
  } = useAccountsQuery({ enabled })

  const internalAccount = data?.data.find(
    (account) => account.source === EnumAccountSource.INTERNAL
  )

  const {
    data: account,
    isError: isInternalAccountError,
    isLoading: isInternalAccountLoading,
    refetch: refetchInternalAccount,
    isSuccess: isInternalAccountSuccess,
  } = useAccountQuery(internalAccount?.id, {
    enabled: !!internalAccount,
  })

  const errorRefetch = useCallback(() => {
    if (isAccountsError) {
      refetchAccounts()
    }
    if (isInternalAccountError) {
      refetchInternalAccount()
    }
  }, [
    isAccountsError,
    isInternalAccountError,
    refetchAccounts,
    refetchInternalAccount,
  ])

  return {
    errorRefetch,
    isLoading: isInternalAccountLoading || isAccountsLoading,
    isError: isInternalAccountError || isAccountsError,
    isSuccess:
      isAccountsSuccess &&
      ((!!internalAccount && isInternalAccountSuccess) || !internalAccount),
    data: account,
  }
}
