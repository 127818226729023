import { EnumUserCountry } from '@blank/api'

export enum EnumUserLocale {
  FR = 'fr',
  IT = 'it',
}

export const USER_LOCALE_MAP: Record<EnumUserCountry, EnumUserLocale> = {
  [EnumUserCountry.FRA]: EnumUserLocale.FR,
  [EnumUserCountry.ITA]: EnumUserLocale.IT,
}

export const USER_COUNTRY_MAP: Record<EnumUserLocale, EnumUserCountry> = {
  [EnumUserLocale.FR]: EnumUserCountry.FRA,
  [EnumUserLocale.IT]: EnumUserCountry.ITA,
}
