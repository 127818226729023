import { useTranslation } from 'modules/common/hooks/useTranslation'
import { IconCheck, IconCopyText } from '../../_icons/icons'
import { Button, ButtonProps } from '../../_ui/Button/Button'

interface Props {
  onClick?: ButtonProps['onClick']
  isCopied: boolean
  className?: string
}

export const CopyTextPrimaryButton = ({
  onClick,
  isCopied,
  className,
}: Props) => {
  const { t } = useTranslation('common')
  return (
    <Button
      className={className}
      variant="primary"
      color="default"
      type="button"
      onClick={onClick}
      icon={isCopied ? <IconCheck /> : <IconCopyText />}
    >
      {isCopied ? t('copied') : t('copy')}
    </Button>
  )
}
