import { Auth0UserApi, EnumUserCountry } from '@blank/api'
import { UserInfoResponse } from 'auth0'
import { AxiosError } from 'axios'

export interface Auth0Error {
  errorCode: 'auth0_idp_error'
  error: string
  message: string
  statusCode: number
}

export enum EnumLoginErrors {
  INVALID_GRANT = 'invalid_grant',
  TOO_MANY_ATTEMPTS = 'too_many_attempts',
}

export type Auth0User = Auth0UserApi<UserInfoResponse>

export interface AuthenticatedUser {
  firstname?: string
  lastname?: string
  corporateId?: string
  email?: string
  emailVerified?: boolean
  phoneNumber?: string
  phoneNumberPending?: string
  phoneNumberVerified?: boolean
  hasMFA?: boolean
  sub?: string
  roles?: string[]
  signUpInformation?: string[]
  hasCompletedSca?: boolean
  isEnrolled?: boolean
  actionId?: string
  country: EnumUserCountry
}

export const DEVICE_NAME = 'Navigateur Web'

export const DEFAULT_SCOPE = 'openid profile email offline_access'

export function isAxiosError<T>(error: unknown): error is AxiosError<T> {
  return (error as AxiosError<T>).response !== undefined
}
