import { FeatureFlippingApi } from '@blank/api'
import { useAuthenticatedUserCorporateQuery } from 'modules/auth/queries/useAuthenticatedUserCorporateQuery'
import React, { createContext, useContext } from 'react'

export const FeatureFlippingContext = createContext<
  FeatureFlippingApi | undefined
>(undefined)

interface Props {
  children: React.ReactNode
}

const DEFAULT_FEATURE_FLIPPING: FeatureFlippingApi = {
  features: [],
  enableAll: false,
}

const FeatureFlippingContextProvider = ({ children }: Props) => {
  const {
    data: { corporate },
  } = useAuthenticatedUserCorporateQuery()

  const featureFlipping = corporate?.featureFlipping
    ? corporate.featureFlipping
    : DEFAULT_FEATURE_FLIPPING

  return (
    <FeatureFlippingContext.Provider value={featureFlipping}>
      {children}
    </FeatureFlippingContext.Provider>
  )
}

function useFeatureFlippingContext() {
  const context = useContext(FeatureFlippingContext)
  if (context === undefined) {
    throw new Error(
      'useFeatureFlippingContext must be used within a FeatureFlippingContextProvider'
    )
  }
  return context
}
export { FeatureFlippingContextProvider, useFeatureFlippingContext }
