import { useQueryClient } from '@tanstack/react-query'
import {
  clearAccessToken,
  clearRefreshToken,
} from 'modules/auth/lib/auth0/token'
import { useOnUnMount } from 'modules/common/hooks/useOnUnMount'
import { resetAmplitudeUserProperties } from 'modules/common/lib/amplitude'
import { resetGTMProperties } from 'modules/common/lib/gtm/dataLayer'
import { clearSentryUser } from 'modules/common/lib/sentry/user'
import { EnumRoutes } from 'modules/common/routes'
import { clearAllDocumentCreationForms } from 'modules/invoicing/lib/storage/documentCreationForm'
import { useRouter } from 'next/router'
import { useState } from 'react'
import { usePostRevokeRefreshTokenMutation } from '../mutations/usePostRevokeRefreshTokenMutation'

export const useLogoutFlow = (options?: { redirectTo?: string }) => {
  const { redirectTo = EnumRoutes.LOGIN } = options || {}
  const queryClient = useQueryClient()
  const router = useRouter()
  const [loading, setLoading] = useState(false)
  const { mutateAsync: revokeRefreshToken } =
    usePostRevokeRefreshTokenMutation()

  const logout = async () => {
    setLoading(true)
    // Clear all documents creation form from local storage
    clearAllDocumentCreationForms()
    // Clear all user properties from amplitude
    resetAmplitudeUserProperties()
    // Clear all user properties from GTM
    resetGTMProperties()
    try {
      await revokeRefreshToken()
    } finally {
      clearAccessToken()
      clearRefreshToken()
      clearSentryUser()
      queryClient.clear()
      await router.replace(redirectTo)
      setLoading(false)
    }
  }

  useOnUnMount(() => setLoading(false))

  return { logout, loading }
}
