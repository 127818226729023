import { EnumOfferType } from '@blank/api'
import { useFeatureFlipping } from 'modules/common/hooks/useFeatureFlipping'
import { EnumRoutes } from 'modules/common/routes'
import {
  EnumUserOnboardingState,
  useAuthenticatedUserOnboardingState,
} from 'modules/onboarding/hooks/useAuthenticatedUserOnboardingState'
import { useRouter } from 'next/router'
import { useCallback, useMemo } from 'react'
import { useRequiredAuthDataQueries } from './useRequiredAuthDataQueries'

const onboardingRoutes = [
  EnumRoutes.EMAIL_VERIFICATION,
  EnumRoutes.PHONE_NUMBER_VERIFICATION,
  EnumRoutes.SECURING_DEVICE,
  EnumRoutes.SCA_LOGIN,
  EnumRoutes.SIGNUP_INFORMATION,
  EnumRoutes.MANAGEMENT_TOOLS_SIGNUP,
  EnumRoutes.LOGIN,
  EnumRoutes.MANDATORY_INFORMATION,
  EnumRoutes.QUICK_SIGNUP,
]

const strictlyManagementToolsForbiddenRoutes = [
  EnumRoutes.KYC_ROOT,
  EnumRoutes.OFFER_SELECTION,
]

export const useRedirectAuthenticatedUser = () => {
  const { canAccessSca } = useFeatureFlipping()
  const router = useRouter()
  const userOnboardingState = useAuthenticatedUserOnboardingState()

  const {
    data: { user, corporate, isKycComplete, corporateOffer },
  } = useRequiredAuthDataQueries()

  const isStrictlyManagementTools =
    corporateOffer?.offerType === EnumOfferType.STRICTLY_MANAGEMENT_TOOLS

  const userNeedsToVerifyEmail = !user?.emailVerified
  const userNeedsToEnroll = !user?.isEnrolled && canAccessSca && isKycComplete
  const userNeedsToSecureLogin =
    user?.isEnrolled &&
    user?.actionId &&
    !user?.hasCompletedSca &&
    canAccessSca &&
    isKycComplete
  const userNeedsToCompleteOnboarding = !isKycComplete

  const shouldRedirectUserToHomepage =
    onboardingRoutes.includes(router.route as EnumRoutes) ||
    (isStrictlyManagementTools &&
      strictlyManagementToolsForbiddenRoutes.includes(
        router.route as EnumRoutes
      ))

  const shouldRedirectToOfferSelectionPage =
    // User should never access the Offer Selection page if isStrictlyManagementTools
    !isStrictlyManagementTools &&
    // User should first select an offer before accessing the KYC page
    ((router.route === EnumRoutes.KYC_ROOT &&
      corporate?.subscriptions?.length === 0) ||
      // User should be redirected to KYC page if they just finished company selection
      (userOnboardingState === EnumUserOnboardingState.COMPLETED &&
        router.route === EnumRoutes.MANDATORY_INFORMATION))

  const shouldRedirectToKycRoot =
    // User should never access the KYC page if isStrictlyManagementTools
    !isStrictlyManagementTools &&
    router.route === EnumRoutes.OFFER_SELECTION &&
    corporate?.subscriptions &&
    corporate?.subscriptions.length > 0

  const redirectRoute = useMemo(() => {
    if (userNeedsToVerifyEmail) {
      if (
        router.route !== EnumRoutes.EMAIL_VERIFICATION &&
        router.route !== EnumRoutes.SIGNUP_INFORMATION &&
        router.route !== EnumRoutes.MANAGEMENT_TOOLS_SIGNUP &&
        router.route !== EnumRoutes.QUICK_SIGNUP
      ) {
        return EnumRoutes.EMAIL_VERIFICATION
      }
    } else if (userOnboardingState !== EnumUserOnboardingState.COMPLETED) {
      if (router.route !== EnumRoutes.MANDATORY_INFORMATION) {
        return EnumRoutes.MANDATORY_INFORMATION
      }
    } else if (userNeedsToEnroll) {
      if (router.route !== EnumRoutes.SECURING_DEVICE) {
        return EnumRoutes.SECURING_DEVICE
      }
    } else if (userNeedsToSecureLogin) {
      if (router.route !== EnumRoutes.SCA_LOGIN) {
        return EnumRoutes.SCA_LOGIN
      }
    } else if (shouldRedirectToOfferSelectionPage) {
      return EnumRoutes.OFFER_SELECTION
    } else if (shouldRedirectToKycRoot) {
      return EnumRoutes.KYC_ROOT
    } else if (userNeedsToCompleteOnboarding && shouldRedirectUserToHomepage) {
      if (router.route !== EnumRoutes.KYC_ROOT) {
        return EnumRoutes.HOME
      }
    } else {
      if (shouldRedirectUserToHomepage) {
        return EnumRoutes.HOME
      }
      return null
    }
  }, [
    router.route,
    shouldRedirectToKycRoot,
    shouldRedirectToOfferSelectionPage,
    shouldRedirectUserToHomepage,
    userNeedsToCompleteOnboarding,
    userNeedsToEnroll,
    userNeedsToSecureLogin,
    userNeedsToVerifyEmail,
    userOnboardingState,
  ])

  const redirectAuthenticatedUser = useCallback(() => {
    if (redirectRoute) {
      router.replace(redirectRoute)
    }
  }, [router, redirectRoute])

  return {
    redirectAuthenticatedUser,
    isRedirectNeeded: !!redirectRoute,
  }
}
