import { mergeClasses } from '@blank/utilities'
import { rPixelString } from 'modules/common/lib/validation/rules'
import ReactModal from 'react-modal'
import { ModalWidth } from './Modal'

export type TransitionType = 'transition-opacity' | 'transition-none'

interface ModalRootProps extends ReactModal.Props {
  overlayTransitionType?: TransitionType
  width: ModalWidth
}

export const ModalRoot = ({
  children,
  className,
  overlayTransitionType = 'transition-opacity',
  width,
  ...props
}: ModalRootProps) => (
  <ReactModal
    style={{ content: { ...(rPixelString.test(width) && { width }) } }}
    className={{
      base: mergeClasses(
        'mx-4 flex flex-col items-center overflow-hidden text-center',
        {
          'max-w-max': width === 'full',
          'max-w-modal-base md:w-modal-base': width === 'base',
          'max-w-modal-medium md:w-modal-medium': width === 'medium',
          'max-w-modal-large md:w-modal-large': width === 'large',
        },
        className
      ),
      afterOpen:
        'absolute inset-auto m-auto max-h-modal overflow-auto rounded-3xl bg-white shadow-md outline-none',
      beforeClose: '',
    }}
    overlayClassName={{
      base: mergeClasses('flex justify-center items-center opacity-0 z-50', {
        'transition-opacity duration-500':
          overlayTransitionType === 'transition-opacity',
      }),
      afterOpen: 'fixed bg-info-400 inset-0 bg-opacity-90 opacity-100',
      beforeClose: '',
    }}
    shouldCloseOnOverlayClick
    shouldCloseOnEsc
    {...props}
  >
    {children}
  </ReactModal>
)
