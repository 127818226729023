import { EnumUserLocale } from 'modules/common/types/locale'
import { FALLBACK_LOCALE } from '_constants'

export const GTM_CONTAINER_ID_BY_COUNTRY_MAP: Record<
  EnumUserLocale,
  string | undefined
> = {
  [EnumUserLocale.FR]: process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID,
  [EnumUserLocale.IT]: process.env.NEXT_PUBLIC_ITALIAN_GOOGLE_TAG_MANAGER_ID,
}

export const GTM_AUTH_TOKEN_BY_COUNTRY_MAP: Record<
  EnumUserLocale,
  string | undefined
> = {
  [EnumUserLocale.FR]: process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_AUTH_TOKEN,
  [EnumUserLocale.IT]:
    process.env.NEXT_PUBLIC_ITALIAN_GOOGLE_TAG_MANAGER_AUTH_TOKEN,
}

export const GTM_ENV_ID_BY_COUNTRY_MAP: Record<
  EnumUserLocale,
  string | undefined
> = {
  [EnumUserLocale.FR]: process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ENV,
  [EnumUserLocale.IT]: process.env.NEXT_PUBLIC_ITALIAN_GOOGLE_TAG_MANAGER_ENV,
}

export const getGTMVariablesByLocale = (
  locale: EnumUserLocale = FALLBACK_LOCALE
) => {
  return {
    gtmAuthToken: GTM_AUTH_TOKEN_BY_COUNTRY_MAP[locale],
    gtmContainerId: GTM_CONTAINER_ID_BY_COUNTRY_MAP[locale],
    gtmEnvId: GTM_ENV_ID_BY_COUNTRY_MAP[locale],
  }
}
