export const SENTRY_DSN =
  process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN

export const SENTRY_TRACES_SAMPLE_RATE = 0.1

export const SENTRY_ENABLED = process.env.NODE_ENV !== 'development'

export const SENTRY_ENVIRONMENT =
  process.env.VERCEL_ENV ||
  process.env.NEXT_PUBLIC_VERCEL_ENV ||
  process.env.NODE_ENV
