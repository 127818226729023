import { mergeClasses } from '@blank/utilities'
import { useScrollTop } from 'modules/common/hooks/useScrollTop'
import React from 'react'
import { HideModalContextProvider } from '../../contexts/HideModalContext'
import { ModalBody } from './ModalBody'
import { ModalFooter } from './ModalFooter'
import { ModalHeader } from './ModalHeader'
import { ModalRoot, TransitionType } from './ModalRoot'

export type ModalWidth = 'base' | 'medium' | 'large' | 'full' | `${number}px`

export interface ModalProps {
  isOpen: boolean
  onClose: () => void
  onBack?: () => void
  classNames?: {
    modalRoot?: string
    modalBody?: string
    modalFooter?: string
  }
  headerTitle?: string
  width?: ModalWidth
  overlayTransitionType?: TransitionType
  shouldCloseOnEsc?: boolean
  shouldCloseOnOverlayClick?: boolean
  content: React.ReactNode
  displayHeader: boolean
  displayCloseHeaderButton: boolean
  footer?: React.ReactNode
  hideModal: () => void
}

export const Modal = ({
  isOpen,
  onClose,
  onBack,
  classNames,
  headerTitle,
  overlayTransitionType,
  shouldCloseOnEsc,
  shouldCloseOnOverlayClick,
  width = 'full',
  displayHeader,
  displayCloseHeaderButton,
  content,
  footer,
  hideModal,
}: ModalProps) => {
  const { onScroll, scrollTop } = useScrollTop()

  return (
    <HideModalContextProvider hideModal={hideModal}>
      <ModalRoot
        isOpen={isOpen}
        onRequestClose={onClose}
        className={classNames?.modalRoot}
        overlayTransitionType={overlayTransitionType}
        shouldCloseOnEsc={shouldCloseOnEsc}
        shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
        width={width}
      >
        {displayHeader && (
          <ModalHeader
            headerTitle={headerTitle}
            onClose={onClose}
            onBack={onBack}
            scrollTop={scrollTop}
            displayCloseHeaderButton={displayCloseHeaderButton}
          />
        )}
        <ModalBody onScroll={onScroll} className={classNames?.modalBody}>
          {content}
        </ModalBody>
        {!!footer && (
          <ModalFooter
            className={mergeClasses(
              'flex-col space-y-4 md:flex-row md:space-x-4 md:space-y-0',
              classNames?.modalFooter
            )}
          >
            {footer}
          </ModalFooter>
        )}
      </ModalRoot>
    </HideModalContextProvider>
  )
}
