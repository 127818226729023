import { CorporateOfferApi } from '@blank/api'
import { useAuthenticatedUserQuery } from 'modules/auth/queries/useAuthenticatedUserQuery'
import { useCorporateOfferQuery } from 'modules/corporate/queries/useCorporateOfferQuery'
import { createContext, PropsWithChildren, useContext } from 'react'

interface CorporateOfferContextProps {
  corporateOffer?: CorporateOfferApi
  isLoading: boolean
  isError: boolean
  refetch: () => void
}

export const CorporateOfferContext = createContext<
  CorporateOfferContextProps | undefined
>(undefined)

const CorporateOfferContextProvider = ({ children }: PropsWithChildren) => {
  const { data: authenticatedUser } = useAuthenticatedUserQuery()

  const {
    data: corporateOffer,
    isLoading,
    isError,
    refetch,
  } = useCorporateOfferQuery(authenticatedUser?.corporateId)

  return (
    <CorporateOfferContext.Provider
      value={{ corporateOffer, isLoading, isError, refetch }}
    >
      {children}
    </CorporateOfferContext.Provider>
  )
}

const useCorporateOfferContext = () => {
  const context = useContext(CorporateOfferContext)
  if (context === undefined) {
    throw new Error(
      'useCorporateOfferContext must be used within a CorporateOfferContextProvider'
    )
  }
  return context
}
export { CorporateOfferContextProvider, useCorporateOfferContext }
