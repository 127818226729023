import { AccountApi } from '@blank/api'
import { useQueryWithAuth } from 'modules/auth/hooks/useQueryWithAuth'
import { QueryOptions } from 'modules/auth/types/queries'
import { fetchAccount } from '../api/fetchAccount'
import { EnumBankQueryKeys } from './keys'

export const useAccountQuery = (
  accountId?: string,
  queryOptions?: QueryOptions<AccountApi, Error>
) => {
  return useQueryWithAuth<AccountApi, Error>({
    queryKey: [EnumBankQueryKeys.ACCOUNTS_RECORD, accountId],
    queryFn: async (_context, accessToken) => {
      if (!accountId) {
        throw new Error('useQueryWithAuth: Missing parameter accountId')
      }

      return fetchAccount({ accountId, accessToken })
    },
    enabled: !!accountId,
    ...queryOptions,
  })
}
