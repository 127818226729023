import { useTranslation } from 'modules/common/hooks/useTranslation'
import { useCallback } from 'react'
import {
  ContentModalProps,
  useContentModal,
} from '../useContentModal/useContentModal'
import {
  TypeformModalContent,
  TypeformModalContentProps,
} from './TypeformModalContent'

export type TypeformModalProps = Omit<ContentModalProps, 'content'> &
  TypeformModalContentProps

export const useTypeformModal = ({
  isLoading = false,
  hiddenFields,
  formId,
  onTypeformSubmitted: onTypeformSubmittedFromProps,
  error,
  ...props
}: TypeformModalProps) => {
  const { i18n } = useTranslation('common-components')

  const onTypeformSubmitted = useCallback(async () => {
    try {
      await onTypeformSubmittedFromProps()
      typeformModal.hideModal()
    } catch (error) {
      typeformModal.hideModal()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onTypeformSubmittedFromProps])

  const typeformModal = useContentModal({
    width: 'large',
    content: (
      <TypeformModalContent
        formId={formId}
        hiddenFields={hiddenFields}
        onTypeformSubmitted={onTypeformSubmitted}
        isLoading={isLoading}
        error={error}
      />
    ),
    i18n,
    classNames: { modalBody: 'h-full', modalRoot: 'h-full' },
    ...props,
  })

  return typeformModal
}
