import { nextApiRoutesClient } from 'modules/common/http/nextApiRoutesClient'

export interface PostRefreshTokenProps {
  scope: string
  deviceName?: string
  actionId?: string
}

export const postRefreshToken = async (payload: PostRefreshTokenProps) => {
  const { data } = await nextApiRoutesClient.post<void>(
    '/api/auth/refreshToken',
    payload
  )

  return data
}
