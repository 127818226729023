import { DEFAULT_SCOPE, DEVICE_NAME } from 'modules/auth/types/auth0'
import { getScaLoginAction } from '../sca'

interface BuildScopeProps {
  withScaReset?: boolean
}

// For login flow, we provide SCA login action id only only if it has been validated
export const buildLoginPayload = (user: {
  email: string
  password: string
}) => {
  const scaLoginAction = getScaLoginAction()
  const actionId = scaLoginAction?.id
  const withActionId = !!scaLoginAction?.isValidated

  return {
    ...user,
    scope: DEFAULT_SCOPE,
    deviceName: DEVICE_NAME,
    ...(withActionId ? { actionId } : {}),
  }
}

// For refresh token flow, we provide SCA login action id as soon as it has been created by the user
export const buildRefreshTokenPayload = ({
  withScaReset,
}: BuildScopeProps = {}) => {
  const scaLoginAction = getScaLoginAction()
  const actionId = scaLoginAction?.id
  const withActionId = !withScaReset && actionId

  return {
    scope: DEFAULT_SCOPE,
    deviceName: DEVICE_NAME,
    ...(withActionId ? { actionId } : {}),
  }
}
