import { mergeClasses } from '@blank/utilities'
import { useMemo } from 'react'
import { ButtonColor, ButtonVariant } from './Button'
import { SPINNER_THEME } from './Spinner.theme'

interface Props {
  variant: ButtonVariant
  color: ButtonColor | undefined
}

const INLINE_SPINNER_BUTTON_VARIANTS: ButtonVariant[] = ['tertiary', 'none']

export const useSpinnerTheme = ({ variant, color = 'none' }: Props) => {
  const spinnerClasses = useMemo(() => {
    if (color) {
      return {
        className: mergeClasses(
          SPINNER_THEME.baseClassName,
          SPINNER_THEME[variant]?.className
        ),
        ...SPINNER_THEME[variant]?.[color],
      }
    }
  }, [color, variant])

  const isSpinnerOnTop = !INLINE_SPINNER_BUTTON_VARIANTS.includes(variant)

  return { isSpinnerOnTop, spinnerClasses }
}
