import { useCallback, useEffect } from 'react'

import { useAuthenticatedUserQuery } from 'modules/auth/queries/useAuthenticatedUserQuery'
import { useCorporateQuery } from 'modules/corporate/queries/useCorporateQuery'

import { EnumBrand } from '@blank/api'
import { USER_COUNTRY_MAP } from 'modules/common/types/locale'
import { useAppContextState } from '../contexts/AppContext'
import { setAmplitudeUserProperties } from '../lib/amplitude'
import { pushGTMProperties } from '../lib/gtm/dataLayer'
import { UserTrackingProperties } from '../types/tracking'
import { useUserLocale } from './useUserLocale'

export const useSetUserTrackingProperties = () => {
  const locale = useUserLocale()

  const { data: user } = useAuthenticatedUserQuery()
  const { data: corporate } = useCorporateQuery(user?.corporateId)
  const { landingPageParameters } = useAppContextState()

  const setUserTrackingProperties = useCallback(
    (properties: UserTrackingProperties) => {
      setAmplitudeUserProperties(properties)
      pushGTMProperties(properties)
    },
    []
  )

  useEffect(() => {
    setUserTrackingProperties({
      corporateId: user?.corporateId,
      email: user?.email,
      affilae_click_id: landingPageParameters?.affilaeClickId,
      awin_click_id: landingPageParameters?.awinClickId,
      gclid: landingPageParameters?.gclid,
      msclkid: landingPageParameters?.msclkid,
      fbclid: landingPageParameters?.fbclid,
      activityCode: corporate?.information?.activityCode,
      activityLabel: corporate?.information?.activityLabel,
      activitySector: corporate?.information?.activitySector,
      isPhysicalPerson: corporate?.information?.isPhysicalPerson,
      legalFormCode: corporate?.information?.legalFormCode,
      createdAt: corporate?.createdAt,
      status: corporate?.statuses,
      auth0Id: user?.sub,
      brand: process.env.NEXT_PUBLIC_BRAND as EnumBrand,
      utmSource:
        corporate?.signUpInformation?.utmSource ||
        landingPageParameters?.utmSource,
      utmCampaign:
        corporate?.signUpInformation?.utmCampaign ||
        landingPageParameters?.utmCampaign,
      utmMedium:
        corporate?.signUpInformation?.utmMedium ||
        landingPageParameters?.utmMedium,
      offerId: corporate?.subscriptions[0]?.offerId,
      country: user?.country || USER_COUNTRY_MAP[locale],
    })
  }, [
    user,
    corporate,
    setUserTrackingProperties,
    landingPageParameters?.affilaeClickId,
    landingPageParameters?.awinClickId,
    landingPageParameters?.gclid,
    landingPageParameters?.utmSource,
    landingPageParameters?.utmCampaign,
    landingPageParameters?.utmMedium,
    locale,
    landingPageParameters?.msclkid,
    landingPageParameters?.fbclid,
  ])
}
