import { EnumUserLocale } from 'modules/common/types/locale'
import { FALLBACK_LOCALE } from '_constants'
import { ConfigType } from '../types'

export const LCL_CONFIG: ConfigType = {
  internalBankName: 'Essentiel Pro',
  locales: (process.env.NEXT_PUBLIC_AVAILABLE_LOCALES || FALLBACK_LOCALE).split(
    ','
  ) as EnumUserLocale[],
  hasSubBanks: false,
  signupConfig: {
    shouldDisplayOperatedBy: true,
    shouldDisplayCommercialSolicitationOptIn: true,
    canAccessManagementOffer: true,
    canAccessQuickSignup: false,
  },
  defaultPublicOfferCount: 2,
  caravelAffiliationCode: 'ESSENTIELPRO',
}
