export enum EnumCorporateQueryKeys {
  CORPORATES_RECORD = 'CORPORATES_RECORD',
  CORPORATES_STATUS = 'CORPORATES_STATUS',
  CORPORATES_SEARCH = 'CORPORATES_SEARCH',
  COMPANY_SEARCH_BY_IDENTIFICATION_NUMBER = 'COMPANY_SEARCH_BY_IDENTIFICATION_NUMBER',
  CORPORATES_LIFECYCLE = 'CORPORATES_LIFECYCLE',
  CORPORATES_OFFER = 'CORPORATES_OFFER',
  CORPORATES_KYC_ACTIVITY_FORM_URL = 'CORPORATES_KYC_ACTIVITY_FORM_URL',
  CORPORATES_SUBBANKS_LIST = 'CORPORATES_SUBBANKS_LIST',
  CORPORATES_IDENTIFICATION_SESSION = 'CORPORATES_IDENTIFICATION_SESSION',
  CORPORATE_ADVANCED_SEARCH = 'CORPORATE_ADVANCED_SEARCH',
  CORPORATES_SPONSORSHIP = 'CORPORATES_SPONSORSHIP',
}
