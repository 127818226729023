import { CorporatePaymentServiceProviderCustomerApi } from '@blank/api'
import { apiClient } from 'modules/common/http/apiClient'
import { getAuthorizationHeader } from 'modules/common/lib/api/getAuthorizationHeader'

interface Props {
  corporateId: string
  accessToken: string
}

export const fetchCorporatePaymentMethods = async ({
  corporateId,
  accessToken,
}: Props) => {
  const apiUrl = `/v1/billing/corporate/${corporateId}/payment-service-provider/customer`

  const { data } = await apiClient.get<
    CorporatePaymentServiceProviderCustomerApi[] | null
  >(apiUrl, {
    headers: getAuthorizationHeader(accessToken),
  })

  return data || []
}
