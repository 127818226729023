import { mergeClasses } from '@blank/utilities'
import { useBackgroundIconTheme } from './useBackgroundIconTheme'

export const BACKGROUND_ICON_COLORS = [
  'important',
  'success',
  'warning',
  'danger',
  'disabled',
] as const
export const BACKGROUND_ICON_VARIANTS = ['light', 'strong'] as const
export const BACKGROUND_ICON_SIZE = ['large', 'small'] as const

export type BackgroundIconVariant = (typeof BACKGROUND_ICON_VARIANTS)[number]
export type BackgroundIconColor = (typeof BACKGROUND_ICON_COLORS)[number]
export type BackgroundIconSize = (typeof BACKGROUND_ICON_SIZE)[number]

interface Props {
  color: BackgroundIconColor
  size: BackgroundIconSize
  variant: BackgroundIconVariant
  icon: React.ReactNode
}
export const BackgroundIcon = ({ color, size, variant, icon }: Props) => {
  const { themeClassName } = useBackgroundIconTheme({ variant, color })
  return (
    <div
      className={mergeClasses(
        'flex shrink-0 items-center justify-center rounded-lg',
        themeClassName,
        {
          'h-[34px] w-[34px]': size === 'small',
          'h-10 w-10': size === 'large',
        }
      )}
    >
      {icon}
    </div>
  )
}
