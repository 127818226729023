import { EnumDocumentType } from '@blank/api'
import localStorage from 'modules/common/lib/storage/localStorage'
import { DocumentInformationFormData } from 'modules/invoicing/components/documents/DocumentFormContainer/types'
import { DeepPartial } from 'ts-essentials'
import { EnumLocalStorageKeys } from '_constants'

export const storeDocumentCreationForm = (
  documentType: EnumDocumentType,
  values: DeepPartial<DocumentInformationFormData>
) => {
  if (documentType === EnumDocumentType.ESTIMATE) {
    localStorage.store(EnumLocalStorageKeys.ESTIMATE_CREATION_FORM, values)
  } else if (documentType === EnumDocumentType.INVOICE) {
    localStorage.store(EnumLocalStorageKeys.INVOICE_CREATION_FORM, values)
  }
}

export const getDocumentInformationFormFromLocalStorage = (
  documentType: EnumDocumentType,
  customerId: string | undefined
): DocumentInformationFormData | null => {
  const defaultValues = localStorage.retrieve<DocumentInformationFormData>(
    documentType === EnumDocumentType.ESTIMATE
      ? EnumLocalStorageKeys.ESTIMATE_CREATION_FORM
      : EnumLocalStorageKeys.INVOICE_CREATION_FORM
  )
  // stored customer must match customerId URL query, if any
  if (!customerId || defaultValues?.customer?.value === customerId) {
    return defaultValues ?? null
  }
  return null
}

export const clearDocumentCreationForm = (documentType: EnumDocumentType) => {
  if (documentType === EnumDocumentType.ESTIMATE) {
    localStorage.clear(EnumLocalStorageKeys.ESTIMATE_CREATION_FORM)
  } else if (documentType === EnumDocumentType.INVOICE) {
    localStorage.clear(EnumLocalStorageKeys.INVOICE_CREATION_FORM)
  }
}

export const clearAllDocumentCreationForms = () => {
  localStorage.clear(EnumLocalStorageKeys.ESTIMATE_CREATION_FORM)
  localStorage.clear(EnumLocalStorageKeys.INVOICE_CREATION_FORM)
}
