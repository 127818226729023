import { FALLBACK_LOCALE } from '_constants'
import { EXTERNAL_LINKS } from '_constants/externalLinks'
import { ExternalLinks } from '_constants/externalLinks/type'
import { useUserLocale } from './useUserLocale'

export const useCountryExternalLinks = (): ExternalLinks => {
  const userLocale = useUserLocale()

  return EXTERNAL_LINKS[userLocale] || EXTERNAL_LINKS[FALLBACK_LOCALE]
}
