import { useTranslation } from 'modules/common/hooks/useTranslation'
import { useContentModal } from 'modules/common/modals/useContentModal/useContentModal'
import { OrderNewTokamakCardMigrationModalContent } from './OrderNewTokamakCardMigrationModalContent/OrderNewTokamakCardMigrationModalContent'

export const useOrderNewTokamakCardMigrationModal = () => {
  const { t, i18n } = useTranslation('bank-components')

  return useContentModal({
    headerTitle: t('orderNewTokamakCardMigrationModal.headerTitle'),
    content: <OrderNewTokamakCardMigrationModalContent />,
    width: 'large',
    i18n,
    shouldCloseOnOverlayClick: false,
    shouldCloseOnEsc: false,
    displayCloseHeaderButton: false,
  })
}
