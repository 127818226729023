const path = require('path')
const dayjs = require('dayjs')

const NEXT_DEFAULT_LOCALE = 'default'
const FALLBACK_LOCALE = 'fr'

module.exports = {
  i18n: {
    defaultLocale: NEXT_DEFAULT_LOCALE,
    locales: [
      NEXT_DEFAULT_LOCALE,
      ...(process.env.NEXT_PUBLIC_AVAILABLE_LOCALES || FALLBACK_LOCALE).split(
        ','
      ),
    ],
  },
  interpolation: {
    format(value, format) {
      // https://www.i18next.com/translation-function/formatting
      if (value instanceof Date) {
        return dayjs(value).format(format)
      }

      if (format === 'priceInEuroFormat') {
        return value.toLocaleString('fr-FR', {
          style: 'currency',
          currency: 'EUR',
          maximumFractionDigits: 2,
        })
      }

      return value
    },
    defaultVariables: {},
  },
  react: { useSuspense: false },
  serializeConfig: false,
  localePath: path.resolve('./public/static/locales'),
  reloadOnPrerender: process.env.NODE_ENV === 'development',
}
