export enum EnumBankQueryKeys {
  ACCOUNT_BENEFICIARY = 'ACCOUNT_BENEFICIARY',
  ACCOUNT_ADD_WIDGET = 'ACCOUNT_ADD_WIDGET',
  ACCOUNT_RIB_BLOB = 'ACCOUNT_RIB_BLOB',
  ACCOUNTS_LIST = 'ACCOUNTS_LIST',
  ACCOUNTS_RECORD = 'ACCOUNTS_RECORD',
  TRANSACTIONS_LIST = 'TRANSACTIONS_LIST',
  BENEFICIARIES_LIST = 'BENEFICIARIES_LIST',
  PROVIDERS_LIST = 'PROVIDER_LIST',
  MANDATES_LIST = 'MANDATES_LIST',
  TRANSACTION_INFORMATION = 'TRANSACTION_INFORMATION',
  ACCOUNT_CARDS_LIST = 'ACCOUNT_CARDS_LIST',
  ACCOUNT_CARD_LIMITS = 'ACCOUNT_CARD_LIMITS',
  ACCOUNT_LIMITS = 'ACCOUNT_LIMITS',
  TRANSFER_ORDERS = 'TRANSFER_ORDERS',
  LINXO_ADD_WIDGET_URL = 'LINXO_ADD_WIDGET_URL',
  LINXO_EDIT_WIDGET_URL = 'LINXO_EDIT_WIDGET_URL',
  MONTH_TRANSACTIONS_BLOB = 'MONTH_TRANSACTIONS_BLOB',
  IBAN_VALIDATION = 'IBAN_VALIDATION',
  TRANSACTION_CERTIFICATE_BLOB = 'TRANSACTION_CERTIFICATE_BLOB',
  ACCOUNT_OPENING_CERTIFICATE_BLOB = 'ACCOUNT_OPENING_CERTIFICATE_BLOB',
  TRANSACTION_REQUEST_STATUS = 'TRANSACTION_REQUEST_STATUS',
}
