import { useTranslation } from 'modules/common/hooks/useTranslation'
import { Corporate } from 'modules/corporate/types/corporates'

interface Props {
  corporate: Corporate
}
export const SupportModalFileComplaintTabTextContent = ({
  corporate,
}: Props) => {
  const { t } = useTranslation('common-components')

  if (corporate?.globalStatusValidatedAt) {
    return (
      <div className="space-y-2 text-left text-info-600">
        <p>{t('supportModal.fileComplaintTab.accountValidated.content')}</p>
        <ul className="list-disc pl-6">
          {t('supportModal.fileComplaintTab.accountValidated.bulletPoints', {
            returnObjects: true,
          }).map((text) => (
            <li key={text}>{text}</li>
          ))}
        </ul>
      </div>
    )
  } else {
    return (
      <div className="text-left text-info-600">
        <p>{t('supportModal.fileComplaintTab.accountNotValidated.content')}</p>
      </div>
    )
  }
}
