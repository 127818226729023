require('typeface-mukta')
require('typeface-montserrat')

import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { buildTimeConfig } from 'config/index'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import duration from 'dayjs/plugin/duration'
import isBetween from 'dayjs/plugin/isBetween'
import isToday from 'dayjs/plugin/isToday'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import utc from 'dayjs/plugin/utc'
import hoistNonReactStatics from 'hoist-non-react-statics'
import { AuthGuards } from 'modules/auth/components/AuthGuards/AuthGuards'
import 'modules/auth/components/SignupStepper/SignupStepper.css'
import { LoginContextProvider } from 'modules/auth/contexts/LoginContext'
import 'modules/bank/components/card/LimitSlider/LimitSlider.css'
import { LayoutEmpty } from 'modules/common/components/_layouts/LayoutEmpty'
import 'modules/common/components/_ui/Autocomplete/Autocomplete.css'
import 'modules/common/components/_ui/Carousel/Carousel.css'
import 'modules/common/components/_ui/FormControl/Calendar/Calendar.css'
import 'modules/common/components/_ui/FormControl/select/Select.css'
import { AppContextProvider } from 'modules/common/contexts/AppContext'
import { FeatureFlippingContextProvider } from 'modules/common/contexts/FeatureFlippingContext'
import { FeedbackAlertContextProvider } from 'modules/common/contexts/FeedbackAlertContext'
import { useInitDayjsLocale } from 'modules/common/hooks/useInitDayjsLocale'
import { useSetSentryUser } from 'modules/common/hooks/useSetSentryUser'
import { useSetUserTrackingProperties } from 'modules/common/hooks/useSetUserTrackingProperties'
import { useTrackPageView } from 'modules/common/hooks/useTrackPageView'
import { initAmplitude } from 'modules/common/lib/amplitude'
import { BlankPage } from 'modules/common/types/page.js'
import { CorporateOfferContextProvider } from 'modules/corporate/contexts/CorporateOfferContext'
import { useInitGTM } from 'modules/corporate/hooks/useInitGTM'
import { appWithTranslation } from 'next-i18next'
import { DefaultSeo } from 'next-seo'
import type { AppProps } from 'next/app'
import { useRouter } from 'next/router'
import React, { ReactElement } from 'react'
import ReactModal from 'react-modal'
import { ModalProvider } from 'react-modal-hook'
import { TransitionGroup } from 'react-transition-group'
import 'styles/index.css'
import nextI18NextConfig from '../../next-i18next.config.js'

nextI18NextConfig.interpolation.defaultVariables = {
  internalBankName: buildTimeConfig.internalBankName,
}

dayjs.extend(customParseFormat)
dayjs.extend(duration)
dayjs.extend(isToday)
dayjs.extend(isBetween)
dayjs.extend(localizedFormat)
dayjs.extend(utc)

const queryClient = new QueryClient()

ReactModal.setAppElement('#__next')

// Amplitude needs to be initialized on client side only
if (typeof window !== 'undefined') {
  initAmplitude()
}

type AppPropsWithLayout = AppProps & {
  Component: BlankPage
}
const App = ({ Component, pageProps }: AppPropsWithLayout) => {
  const getLayout =
    Component.getLayout ||
    ((page: ReactElement) => <LayoutEmpty>{page}</LayoutEmpty>)
  const pageType = (Component as BlankPage).type
  const router = useRouter()
  const uri = (process.env.VERCEL_URL || '') + router.route

  useInitDayjsLocale()
  useInitGTM()
  useTrackPageView()
  useSetUserTrackingProperties()
  useSetSentryUser()

  return (
    <AuthGuards pageType={pageType}>
      <DefaultSeo
        dangerouslySetAllPagesToNoIndex={!process.env.NEXT_PUBLIC_IS_PRODUCTION}
        dangerouslySetAllPagesToNoFollow={
          !process.env.NEXT_PUBLIC_IS_PRODUCTION
        }
        canonical={uri}
        openGraph={{
          type: 'website',
          locale: 'fr_FR',
          url: uri,
          site_name: 'Blank',
        }}
        twitter={{
          handle: '@getblankapp',
          site: '@getblankapp',
          cardType: 'summary_large_image',
        }}
        titleTemplate={`%s | ${buildTimeConfig?.internalBankName}`}
      />
      {getLayout(<Component {...pageProps} />)}
    </AuthGuards>
  )
}

const appWithProviders = (
  WrappedComponent: React.ComponentType<AppPropsWithLayout>
) => {
  const AppWithTranslation = (props: AppPropsWithLayout) => {
    return (
      <AppContextProvider>
        <LoginContextProvider>
          <QueryClientProvider client={queryClient}>
            <FeatureFlippingContextProvider>
              <CorporateOfferContextProvider>
                <FeedbackAlertContextProvider>
                  <ModalProvider rootComponent={TransitionGroup}>
                    <WrappedComponent {...props} />
                  </ModalProvider>
                </FeedbackAlertContextProvider>
              </CorporateOfferContextProvider>
            </FeatureFlippingContextProvider>
            <ReactQueryDevtools
              initialIsOpen={false}
              buttonPosition="bottom-right"
            />
          </QueryClientProvider>
        </LoginContextProvider>
      </AppContextProvider>
    )
  }

  return hoistNonReactStatics(AppWithTranslation, WrappedComponent)
}

export default appWithTranslation(appWithProviders(App), nextI18NextConfig)
