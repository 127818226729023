import { EnumCorporateStatus, EnumBillingServiceId } from '@blank/api'
import { useAuthenticatedUserQuery } from 'modules/auth/queries/useAuthenticatedUserQuery'
import { EnumSupportKey } from 'modules/common/types/support'
import { useCorporateOfferQuery } from 'modules/corporate/queries/useCorporateOfferQuery'
import { useCorporateStatusQuery } from 'modules/corporate/queries/useCorporateStatusQuery'

export const useSupportContext = (): EnumSupportKey => {
  const { data: user } = useAuthenticatedUserQuery()
  const { data: corporateStatus } = useCorporateStatusQuery(user?.corporateId)
  const { data: offer } = useCorporateOfferQuery(user?.corporateId)

  const hasSupportComplete = (offer?.offerPackages || []).some(
    ({ serviceId }) => serviceId === EnumBillingServiceId.SUPPORT_COMPLETE
  )

  if (corporateStatus !== EnumCorporateStatus.VALIDATED) {
    return EnumSupportKey.NOT_VALIDATED
  }

  return hasSupportComplete
    ? EnumSupportKey.SUPPORT_COMPLETE
    : EnumSupportKey.SUPPORT_CONFORT
}
