import { ListItemsContainer } from '../../_ui/ListItemsContainer/ListItemsContainer'
import { EmailTouchpointListItem } from './EmailTouchpointListItem/EmailTouchpointListItem'
import { PhoneTouchpointListItem } from './PhoneTouchpointListItem/PhoneTouchpointListItem'
import { WhatsAppTouchpointListItem } from './WhatsAppTouchpointListItem/WhatsAppTouchpointListItem'

interface Props {
  emailSupport?: string
  phoneSupport?: string
  whatsAppSupportNumber?: string
}

export const SupportModalTouchpoints = ({
  emailSupport,
  phoneSupport,
  whatsAppSupportNumber,
}: Props) => {
  return (
    <ListItemsContainer>
      {!!phoneSupport && (
        <PhoneTouchpointListItem phoneSupport={phoneSupport} />
      )}
      {!!emailSupport && (
        <EmailTouchpointListItem emailSupport={emailSupport} />
      )}
      {!!whatsAppSupportNumber && (
        <WhatsAppTouchpointListItem
          whatsAppSupportNumber={whatsAppSupportNumber}
        />
      )}
    </ListItemsContainer>
  )
}
