import { useTranslation } from 'modules/common/hooks/useTranslation'
import { externalLinkProps } from 'modules/common/lib/helpers/externalLinkProps'
import { SupportTouchpoints } from 'modules/common/types/support'
import { Button } from '../../_ui/Button/Button'
import { SupportModalTouchpoints } from '../SupportModalTouchpoints/SupportModalTouchpoints'

interface Props {
  supportTouchpoints: SupportTouchpoints
}

export const SupportModalHelpTab = ({
  supportTouchpoints: {
    emailSupport,
    phoneSupport,
    whatsAppSupportNumber,
    faqURL,
  },
}: Props) => {
  const { t } = useTranslation('common-components')

  const isSupportAvailable =
    !!emailSupport || !!phoneSupport || !!whatsAppSupportNumber

  return (
    <div className="self-center text-left">
      {faqURL && (
        <>
          <p className="label-base pb-2 text-info-400">
            {t('supportModal.helpTab.faqTitle')}
          </p>
          <p className="pb-4">{t('supportModal.helpTab.faqText')}</p>

          <Button
            variant="primary"
            color="default"
            className="mb-8 w-full min-w-full sm:min-w-0 sm:px-24"
            href={faqURL}
            {...externalLinkProps}
          >
            {t('supportModal.helpTab.button')}
          </Button>
        </>
      )}
      {isSupportAvailable && (
        <>
          <p className="pb-2 font-semibold uppercase text-info-400">
            {t('supportModal.helpTab.helpTitle')}
          </p>
          <p className="pb-8">{t('supportModal.helpTab.helpText')}</p>
          <SupportModalTouchpoints
            emailSupport={emailSupport}
            phoneSupport={phoneSupport}
            whatsAppSupportNumber={whatsAppSupportNumber}
          />
        </>
      )}
    </div>
  )
}
