import { AccountApi } from '@blank/api'
import { apiClient } from 'modules/common/http/apiClient'
import { getAuthorizationHeader } from 'modules/common/lib/api/getAuthorizationHeader'
import { mapAccountToAccountWithFormattedName } from '../lib/transform/account'

interface Props {
  accountId: string
  accessToken: string
}

export const fetchAccount = async ({
  accountId,
  accessToken,
}: Props): Promise<AccountApi> => {
  const apiUrl = `/v1/bank/accounts/${accountId}`

  const { data: account } = await apiClient.get<AccountApi>(apiUrl, {
    headers: getAuthorizationHeader(accessToken),
  })
  return mapAccountToAccountWithFormattedName(account)
}
