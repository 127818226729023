import { trackEvent } from 'modules/common/lib/amplitude/trackEvent'
import { useRouter } from 'next/router'
import { useCallback, useEffect } from 'react'
import { EnumCommonTrackingEvents } from '../lib/amplitude/events'

export const useTrackPageView = () => {
  const router = useRouter()

  const handleRouteChange = useCallback((path: string) => {
    trackEvent({
      eventType: EnumCommonTrackingEvents.PAGEVIEW,
      eventProperties: {
        path,
        url: window.location.href,
      },
    })
  }, [])

  // Handle navigation tracking while router path changes
  useEffect(() => {
    handleRouteChange(router.asPath)
  }, [handleRouteChange, router.asPath])
}
