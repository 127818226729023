import { BlankPage } from 'modules/common/types/page'
import { PropsWithChildren } from 'react'
import { AuthenticatedPageGuard } from './AuthenticatedPageGuard/AuthenticatedPageGuard'
import { NotAuthenticatedPageGuard } from './NotAuthenticatedPageGuard/NotAuthenticatedPageGuard'

interface Props {
  pageType: BlankPage['type']
}

export const AuthGuards = ({
  pageType,
  children,
}: PropsWithChildren<Props>) => {
  switch (pageType) {
    case 'authenticated':
      return <AuthenticatedPageGuard>{children}</AuthenticatedPageGuard>
    case 'notAuthenticated':
      return <NotAuthenticatedPageGuard>{children}</NotAuthenticatedPageGuard>
    case 'detached':
      return <>{children}</>
  }
}
