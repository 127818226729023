import { CorporateLifecycleApi } from '@blank/api'
import { useQueryWithAuth } from 'modules/auth/hooks/useQueryWithAuth'
import { QueryOptions } from 'modules/auth/types/queries'
import { fetchCorporateLifecycle } from '../api/fetchCorporateLifecycle'
import { EnumCorporateQueryKeys } from './keys'

export const useCorporateLifecycleQuery = (
  corporateId?: string,
  queryOptions?: QueryOptions<CorporateLifecycleApi, Error>
) => {
  return useQueryWithAuth<CorporateLifecycleApi, Error>({
    queryKey: [EnumCorporateQueryKeys.CORPORATES_LIFECYCLE, corporateId],
    queryFn: async (_context, accessToken) => {
      if (!corporateId) {
        throw new Error(
          'useCorporateLifecycleQuery: Missing parameter corporateId'
        )
      }

      return fetchCorporateLifecycle({ corporateId, accessToken })
    },
    enabled: !!corporateId,
    ...queryOptions,
  })
}
