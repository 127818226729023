import defaultI18n, { i18n } from 'i18next'
import { Modal, ModalProps } from 'modules/common/components/Modal/Modal'
import { DependencyList } from 'react'
import { I18nextProvider, initReactI18next } from 'react-i18next'
import { useModal } from 'react-modal-hook'

export type ContentModalProps = Pick<
  ModalProps,
  | 'width'
  | 'classNames'
  | 'headerTitle'
  | 'overlayTransitionType'
  | 'shouldCloseOnEsc'
  | 'shouldCloseOnOverlayClick'
  | 'content'
  | 'footer'
  | 'onBack'
> & {
  onCancel?: () => unknown | Promise<unknown>
  hideOnBack?: boolean
  displayHeader?: boolean
  displayCloseHeaderButton?: boolean
  i18n?: i18n
}

// We need a i18n instance to make the <Trans> components work
defaultI18n
  .use(initReactI18next) // bind react-i18next to the instance
  .init()

interface ReactModalProps {
  in: boolean
  enter: any
  exit: any
  onExited: () => void
}

export const useContentModal = (
  {
    onCancel,
    onBack,
    footer,
    content,
    i18n = defaultI18n,
    hideOnBack = false,
    displayHeader = true,
    displayCloseHeaderButton = true,
    ...props
  }: ContentModalProps,
  deps: DependencyList = []
) => {
  const onCloseCallback = async () => {
    await onCancel?.()
    hideModal()
  }

  const onBackCallback = onBack
    ? async () => {
        await onBack()
        if (hideOnBack) {
          hideModal()
        }
      }
    : undefined

  const [showModal, hideModal] = useModal(
    ({ in: open }: ReactModalProps) => (
      <Modal
        isOpen={open}
        onClose={onCloseCallback}
        onBack={onBackCallback}
        footer={
          footer && <I18nextProvider i18n={i18n}>{footer}</I18nextProvider>
        }
        content={<I18nextProvider i18n={i18n}>{content}</I18nextProvider>}
        hideModal={hideModal}
        displayHeader={displayHeader}
        displayCloseHeaderButton={displayCloseHeaderButton}
        {...props}
      />
    ),
    [footer, content, ...deps]
  )

  return {
    showModal,
    hideModal,
  }
}
