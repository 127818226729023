import { FetchAccountsResponseApi } from '@blank/api'
import { apiClient } from 'modules/common/http/apiClient'
import { getAuthorizationHeader } from 'modules/common/lib/api/getAuthorizationHeader'
import { mapAccountToAccountWithFormattedName } from '../lib/transform/account'

interface Props {
  accessToken: string
}

export const fetchAccounts = async ({
  accessToken,
}: Props): Promise<FetchAccountsResponseApi> => {
  const apiUrl = `/v1/bank/accounts`

  const { data: response } = await apiClient.get<FetchAccountsResponseApi>(
    apiUrl,
    {
      headers: getAuthorizationHeader(accessToken),
    }
  )

  return {
    ...response,
    data: response.data.map(mapAccountToAccountWithFormattedName),
  }
}
