import React from 'react'
import ContentLoader, { IContentLoaderProps } from 'react-content-loader'

export interface SkeletonProps extends IContentLoaderProps {
  className?: string
}

export const Skeleton = ({ ...props }: SkeletonProps) => {
  return (
    <ContentLoader
      speed={1}
      viewBox="0 0 24 24"
      preserveAspectRatio="none"
      {...props}
    >
      <rect width="100%" height="100%" />
    </ContentLoader>
  )
}
