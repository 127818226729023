import React, { createContext, useContext } from 'react'

interface HideModalAction {
  hideModal: () => void
}

const HideModalActionContext = createContext<HideModalAction>({
  hideModal: () => null,
})

interface Props {
  children: React.ReactNode
  hideModal: () => void
}
const HideModalContextProvider = ({ children, hideModal }: Props) => {
  return (
    <HideModalActionContext.Provider value={{ hideModal }}>
      {children}
    </HideModalActionContext.Provider>
  )
}

function useHideModalContext() {
  const context = useContext(HideModalActionContext)
  if (context === undefined) {
    throw new Error(
      'useHideModalContext must be used within a HideModalContextProvider'
    )
  }
  return context
}
export { HideModalContextProvider, useHideModalContext }
