import {
  EnumSupportModalTabs,
  SupportTouchpoints,
} from 'modules/common/types/support'
import { SupportModalFileComplaintTab } from './SupportModalFileComplaintTab/SupportModalFileComplaintTab'
import { SupportModalHelpTab } from './SupportModalHelpTab/SupportModalHelpTab'

interface Props {
  activeTab: EnumSupportModalTabs
  supportTouchpoints: SupportTouchpoints
  onFileComplaintButtonClick: () => void
}
export const SupportModalContentTabsContainer = ({
  activeTab,
  supportTouchpoints,
  onFileComplaintButtonClick,
}: Props) => {
  switch (activeTab) {
    case EnumSupportModalTabs.HELP:
      return <SupportModalHelpTab supportTouchpoints={supportTouchpoints} />
    case EnumSupportModalTabs.COMPLAINT:
      return (
        <SupportModalFileComplaintTab
          onFileComplaintButtonClick={onFileComplaintButtonClick}
        />
      )
  }
}
