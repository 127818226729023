import { IconShare, IconWhatsApp } from 'modules/common/components/_icons/icons'
import { BackgroundIcon } from 'modules/common/components/_ui/BackgroundIcon/BackgroundIcon'
import { ListItem } from 'modules/common/components/_ui/ListItem/ListItem'
import { Link } from 'modules/common/components/Link/Link'
import { useTranslation } from 'modules/common/hooks/useTranslation'

interface Props {
  whatsAppSupportNumber: string
}

export const WhatsAppTouchpointListItem = ({
  whatsAppSupportNumber,
}: Props) => {
  const { t } = useTranslation('common-components')

  return (
    <Link href={`https://wa.me/${whatsAppSupportNumber}`}>
      <ListItem
        titlePosition="top"
        title={t('supportModal.supportTouchpoints.whatsAppSupportDetails')}
        subtitle={whatsAppSupportNumber}
        icon={
          <BackgroundIcon
            color="important"
            size="small"
            variant="light"
            icon={<IconWhatsApp />}
          />
        }
        rightElement={<IconShare className="h-5 w-5" />}
      />
    </Link>
  )
}
