import { useAuthenticatedUserQuery } from 'modules/auth/queries/useAuthenticatedUserQuery'
import { useFeatureFlipping } from 'modules/common/hooks/useFeatureFlipping'
import { useSupportModalTabs } from 'modules/common/hooks/useSupportModalTabs'
import { useTranslation } from 'modules/common/hooks/useTranslation'
import { SupportTouchpoints } from 'modules/common/types/support'
import { Tab } from '../Tabs/Tab'
import { Tabs } from '../Tabs/Tabs'
import { SupportModalContentTabsContainer } from './SupportModalContentTabsContainer'

interface Props {
  supportTouchpoints: SupportTouchpoints
  onFileComplaintButtonClick: () => void
}

export const SupportModalContent = ({
  supportTouchpoints,
  onFileComplaintButtonClick,
}: Props) => {
  const { t } = useTranslation('common-components')
  const { data: user } = useAuthenticatedUserQuery()
  const isUserLoggedIn = !!user

  const { activeTab, registerTab, tabs } = useSupportModalTabs()
  const { canAccessFileComplaintSupportTab } = useFeatureFlipping()

  return (
    <>
      {canAccessFileComplaintSupportTab && isUserLoggedIn && (
        <Tabs className="mb-8">
          {tabs.map((tab) => (
            <Tab {...registerTab(tab)} key={tab}>
              {t('supportModal.tab', { context: tab })}
            </Tab>
          ))}
        </Tabs>
      )}

      <SupportModalContentTabsContainer
        activeTab={activeTab}
        supportTouchpoints={supportTouchpoints}
        onFileComplaintButtonClick={onFileComplaintButtonClick}
      />
    </>
  )
}
