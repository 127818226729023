import { CorporateApi } from '@blank/api'
import { useQueryWithAuth } from 'modules/auth/hooks/useQueryWithAuth'
import { QueryOptions } from 'modules/auth/types/queries'
import { Corporate } from 'modules/corporate/types/corporates'
import { fetchCorporate } from '../api/fetchCorporate'
import { getCompanyIdentificationNumber } from '../lib/helpers/company/getCompanyIdentificationNumber'
import { EnumCorporateQueryKeys } from './keys'

export const useCorporateQuery = (
  corporateId: string | undefined,
  queryOptions?: QueryOptions<CorporateApi, Error, Corporate>
) => {
  return useQueryWithAuth<CorporateApi, Error, Corporate>({
    queryKey: [EnumCorporateQueryKeys.CORPORATES_RECORD, corporateId],
    queryFn: async (_context, accessToken) => {
      if (!corporateId) {
        throw new Error('useCorporateQuery: Missing parameter corporateId')
      }
      return fetchCorporate({ corporateId, accessToken })
    },
    ...queryOptions,
    enabled: !!corporateId,
    select: (data) => {
      return {
        ...data,
        information: data.information && {
          ...data.information,
          identificationNumber: getCompanyIdentificationNumber(
            data.information
          ),
        },
      }
    },
  })
}
