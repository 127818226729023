import { mergeClasses } from '@blank/utilities'
import { ReactNode } from 'react'

export interface ListItemProps {
  title: string | ReactNode
  subtitle?: string | ReactNode
  icon?: JSX.Element
  titlePosition?: 'top' | 'bottom'
  rightElement?: React.ReactNode
  className?: string
}
export const ListItem = ({
  subtitle,
  title,
  titlePosition = 'bottom',
  icon,
  rightElement,
  className,
}: ListItemProps) => {
  return (
    <div className={mergeClasses('flex items-center gap-4 py-4', className)}>
      {icon}
      <div
        className={mergeClasses(
          'flex min-h-12 flex-1 flex-col justify-center gap-1',
          { 'flex-col-reverse': titlePosition === 'top' }
        )}
      >
        {subtitle && <p className="text-info-400">{subtitle}</p>}
        <p className="text-lg font-bold">{title}</p>
      </div>
      {rightElement}
    </div>
  )
}
