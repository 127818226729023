import { mergeClasses } from '@blank/utilities'
import Head from 'next/head'
import { PropsWithChildren } from 'react'

interface Props {
  className?: string
}

export const LayoutEmpty = ({
  className,
  children,
}: PropsWithChildren<Props>) => {
  return (
    <div
      className={mergeClasses('flex min-h-screen flex-col bg-white', className)}
    >
      <Head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      </Head>
      {children}
    </div>
  )
}
