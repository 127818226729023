import * as Sentry from '@sentry/nextjs'
import { AuthenticatedUser } from 'modules/auth/types/auth0'

export const clearSentryUser = () => {
  Sentry.configureScope((scope) => scope.setUser(null))
}

export const setSentryUser = (user: AuthenticatedUser) => {
  Sentry.setUser({ email: user.email, corporateId: user.corporateId })
}
