import { EnumBrand } from '@blank/api'
import { BRAND_THEMES_MAP } from '@blank/design-tokens'
import { BLANK_CONFIG } from 'config/blank'
import { CA_CONFIG } from 'config/ca'
import { LCL_CONFIG } from 'config/lcl'
import { ConfigType } from 'config/types'
import { EnumUserLocale } from 'modules/common/types/locale'
import { BLANK_FR_COUNTRY_CONFIG } from './blank/country/fr'
import { BLANK_IT_COUNTRY_CONFIG } from './blank/country/it'
import { CA_FR_COUNTRY_CONFIG } from './ca/country/fr'
import { LCL_FR_COUNTRY_CONFIG } from './lcl/country/fr'
import { LocalizedCountryConfig } from './types'

const buildTimeConfigs: Record<EnumBrand, ConfigType> = {
  blank: BLANK_CONFIG,
  ca: CA_CONFIG,
  lcl: LCL_CONFIG,
}

export const buildTimeBrand: EnumBrand =
  (process.env.NEXT_PUBLIC_BRAND as EnumBrand) ||
  (process.env.STORYBOOK_BRAND as EnumBrand) ||
  EnumBrand.BLANK

export const buildTimeConfig: ConfigType = buildTimeConfigs[buildTimeBrand]

export const brandTheme = BRAND_THEMES_MAP[buildTimeBrand]

export const COUNTRY_CONFIG_MAP: Record<EnumBrand, LocalizedCountryConfig> = {
  [EnumBrand.BLANK]: {
    [EnumUserLocale.FR]: BLANK_FR_COUNTRY_CONFIG,
    [EnumUserLocale.IT]: BLANK_IT_COUNTRY_CONFIG,
  },
  [EnumBrand.CA]: {
    [EnumUserLocale.FR]: CA_FR_COUNTRY_CONFIG,
  },
  [EnumBrand.LCL]: {
    [EnumUserLocale.FR]: LCL_FR_COUNTRY_CONFIG,
  },
}

export const buildTimeCountryConfig = COUNTRY_CONFIG_MAP[buildTimeBrand]
