import { CorporateLifecycleApi } from '@blank/api'
import { apiClient } from 'modules/common/http/apiClient'
import { getAuthorizationHeader } from 'modules/common/lib/api/getAuthorizationHeader'

interface Props {
  corporateId: string
  accessToken: string
}

export const fetchCorporateLifecycle = async ({
  corporateId,
  accessToken,
}: Props) => {
  const apiUrl = `/v1/users/corporates/${corporateId}/lifecycle`

  const { data } = await apiClient.get<CorporateLifecycleApi>(apiUrl, {
    headers: getAuthorizationHeader(accessToken),
  })

  return data
}
