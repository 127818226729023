import { FileComplaintFormSuccessModalContent } from 'modules/common/components/SupportModalContent/FileComplaintFormSuccessModalContent/FileComplaintFormSuccessModalContent'
import { useTranslation } from 'modules/common/hooks/useTranslation'
import { useCtaModal } from 'modules/common/modals/useCtaModal/useCtaModal'

export const useFileComplaintFormSuccessModal = () => {
  const { i18n, t } = useTranslation('common')

  return useCtaModal({
    content: <FileComplaintFormSuccessModalContent />,
    i18n,
    primaryButtonText: t('understood'),
    overlayTransitionType: 'transition-none',
    width: 'base',
  })
}
