import { useAuthenticatedUserCorporateQuery } from 'modules/auth/queries/useAuthenticatedUserCorporateQuery'
import { useTranslation } from 'modules/common/hooks/useTranslation'
import { Button } from '../../_ui/Button/Button'
import { SupportModalFileComplaintTabTextContent } from './SupportModalFileComplaintTabTextContent'

interface Props {
  onFileComplaintButtonClick: () => void
}
export const SupportModalFileComplaintTab = ({
  onFileComplaintButtonClick,
}: Props) => {
  const { t } = useTranslation('common-components')
  const {
    data: { corporate },
  } = useAuthenticatedUserCorporateQuery()

  if (!corporate) {
    return null
  }

  return (
    <div className="flex flex-col gap-6">
      <SupportModalFileComplaintTabTextContent corporate={corporate} />
      <Button
        variant="primary"
        color="default"
        onClick={onFileComplaintButtonClick}
      >
        {t('supportModal.fileComplaintTab.button')}
      </Button>
    </div>
  )
}
