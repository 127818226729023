import { IconEmailRead } from 'modules/common/components/_icons/icons'
import { BackgroundIcon } from 'modules/common/components/_ui/BackgroundIcon/BackgroundIcon'
import { ListItem } from 'modules/common/components/_ui/ListItem/ListItem'
import { CopyText } from 'modules/common/components/CopyText/CopyText'
import { Link } from 'modules/common/components/Link/Link'

interface Props {
  emailSupport: string
}

export const EmailTouchpointListItem = ({ emailSupport }: Props) => {
  return (
    <ListItem
      titlePosition="top"
      title={<Link href={`mailto:${emailSupport}`}>{emailSupport}</Link>}
      icon={
        <BackgroundIcon
          color="important"
          size="small"
          variant="light"
          icon={<IconEmailRead />}
        />
      }
      rightElement={<CopyText textToCopy={emailSupport} />}
    />
  )
}
