import { Widget } from '@typeform/embed-react'
import { ErrorReloadSmall } from 'modules/common/components/ErrorReloadSmall/ErrorReloadSmall'
import { Spinner } from 'modules/common/components/_ui/Spinner/Spinner'

export interface TypeformModalContentProps {
  isLoading?: boolean
  hiddenFields?: Record<string, string>
  formId: string
  onTypeformSubmitted: () => Promise<unknown> | unknown
  error?: { isError: boolean; errorRefetch: () => void }
}

export const TypeformModalContent = ({
  isLoading,
  formId,
  hiddenFields,
  onTypeformSubmitted,
  error,
}: TypeformModalContentProps) => {
  if (isLoading) {
    return (
      <div className="flex h-full w-full items-center justify-center">
        <Spinner className="text-primary" />
      </div>
    )
  }

  if (error?.isError) {
    return <ErrorReloadSmall onReload={error.errorRefetch} />
  }

  return (
    <Widget
      id={formId}
      hidden={hiddenFields}
      onSubmit={onTypeformSubmitted}
      className="h-full w-full"
    />
  )
}
