import { useUserLocale } from 'modules/common/hooks/useUserLocale'
import { getGTMVariablesByLocale } from 'modules/common/lib/gtm/variables'
import { useEffect } from 'react'
import TagManager from 'react-gtm-module'

export const useInitGTM = () => {
  const locale = useUserLocale()

  useEffect(() => {
    const { gtmAuthToken, gtmContainerId, gtmEnvId } =
      getGTMVariablesByLocale(locale)

    if (gtmContainerId) {
      TagManager.initialize({
        gtmId: gtmContainerId,
        auth: gtmAuthToken,
        preview: gtmEnvId,
      })
    }
  }, [locale])

  return
}
