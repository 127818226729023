import { buildTimeCountryConfig } from 'config'
import { FALLBACK_LOCALE } from '_constants'
import { EnumUserLocale } from '../types/locale'
import { useUserLocale } from './useUserLocale'

export const getCountryConfigByLocale = (userLocale: EnumUserLocale) => {
  return (
    buildTimeCountryConfig[userLocale] ||
    buildTimeCountryConfig[FALLBACK_LOCALE]
  )
}

export const useCountryConfig = () => {
  const userLocale = useUserLocale()

  return getCountryConfigByLocale(userLocale)
}
