import { BackgroundIconColor, BackgroundIconVariant } from './BackgroundIcon'

type ColorClassNames = Record<BackgroundIconColor, string>

export const BACKGROUND_ICON_THEME_VARIANT: Record<
  BackgroundIconVariant,
  ColorClassNames
> = {
  light: {
    important: 'bg-primary-90 text-primary-500',
    success: 'bg-success-100 text-success-500',
    warning: 'bg-warning-100 text-warning-500',
    danger: 'bg-danger-90 text-danger-500',
    disabled: 'bg-info-100 text-info-400',
  },
  strong: {
    important: 'bg-primary-500 text-white',
    success: 'bg-success-500 text-white',
    warning: 'bg-warning-500 text-white',
    danger: 'bg-danger-500 text-white',
    disabled: 'bg-info-400 text-white',
  },
}
