import { NextSeo, NextSeoProps } from 'next-seo'

export const Seo = ({
  title,
  description,
  openGraph,
  ...props
}: NextSeoProps) => {
  return (
    <NextSeo
      title={title}
      description={description}
      openGraph={{
        title,
        description,
        ...openGraph,
      }}
      {...props}
    />
  )
}
