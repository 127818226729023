import { useOnUnMount } from 'modules/common/hooks/useOnUnMount'
import { useRef, useState } from 'react'
import { ButtonProps } from '../_ui/Button/Button'

const TIMEOUT = 5000

interface Props {
  textToCopy: string
  onCopy?: () => void
}

export const useCopyText = ({ textToCopy, onCopy }: Props) => {
  const [isCopied, setIsCopied] = useState(false)
  const timerRef = useRef<NodeJS.Timeout | null>(null)

  const onClick: ButtonProps['onClick'] = async (event) => {
    event.stopPropagation()
    setIsCopied(true)
    await navigator.clipboard.writeText(textToCopy)
    onCopy?.()
    timerRef.current = setTimeout(() => {
      setIsCopied(false)
    }, TIMEOUT)
  }

  useOnUnMount(() => {
    timerRef.current && clearTimeout(timerRef.current)
  })

  return { isCopied, onClick }
}
