import { QueryKey, useQuery } from '@tanstack/react-query'
import { useFreshToken } from 'modules/auth/hooks/useFreshToken'
import {
  apiClient,
  COUNTRY_API_BASE_URL_MAP,
} from 'modules/common/http/apiClient'
import {
  COUNTRY_SCA_API_BASE_URL_MAP,
  scaApiClient,
} from 'modules/common/http/scaApiClient'
import { getAuthenticatedUser } from '../lib/auth0/token'
import { QueryFunction, QueryOptions } from '../types/queries'

export const useQueryWithAuth = <
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
>({
  queryKey,
  queryFn,
  ...queryOptions
}: {
  queryFn: QueryFunction<TQueryFnData>
  queryKey: TQueryKey
} & QueryOptions<TQueryFnData, TError, TData>) => {
  const getFreshToken = useFreshToken()

  return useQuery({
    queryKey,
    queryFn: async (context) => {
      const accessToken = await getFreshToken()

      if (!accessToken) {
        throw new Error(`useQueryWithAuth[${queryKey}]: Missing Access Token`)
      }

      const { country } = getAuthenticatedUser(accessToken)

      // Set default base URL depending on the user country
      apiClient.defaults.baseURL = COUNTRY_API_BASE_URL_MAP[country]
      scaApiClient.defaults.baseURL = COUNTRY_SCA_API_BASE_URL_MAP[country]

      return queryFn(context, accessToken)
    },
    ...queryOptions,
  })
}
