import { SignupInformationApi } from '@blank/api'
import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useReducer,
} from 'react'
import { EnumCookieKeys } from '_constants'
import cookieStorage from '../lib/storage/cookieStorage'
import { LandingPageParametersCookieValue } from '../types/tracking'

interface AppContext {
  landingPageParameters?: Omit<SignupInformationApi, 'platform'>
  brand?: string
}

const landingPageParameters =
  cookieStorage.retrieveJSON<LandingPageParametersCookieValue>(
    EnumCookieKeys.LANDING_PAGE_SETTINGS
  ) || {}

const {
  aecid: affilaeClickId,
  awc: awinClickId,
  gclid,
  msclkid,
  fbclid,
  utm_source: utmSource,
  utm_medium: utmMedium,
  utm_campaign: utmCampaign,
  utm_content: utmContent,
  utm_term: utmTerm,
  at_medium: atMedium,
  at_campaign: atCampaign,
  at_name: atName,
  at_content: atContent,
  at_term: atTerm,
  referrer,
} = landingPageParameters

const defaultContext: AppContext = {
  landingPageParameters: {
    affilaeClickId,
    awinClickId,
    msclkid,
    fbclid,
    gclid,
    utmSource,
    utmMedium,
    utmCampaign,
    utmContent,
    utmTerm,
    atCampaign,
    atContent,
    atMedium,
    atName,
    atTerm,
    referrer:
      referrer ||
      (typeof window !== 'undefined' ? document?.referrer : undefined),
  },
  brand:
    process.env.NEXT_PUBLIC_BRAND || process.env.STORYBOOK_BRAND || 'blank',
}

interface Action {
  type: 'set'
  payload: Partial<AppContext>
}

type Dispatch = (action: Action, asyncFn?: () => Promise<any>) => Promise<any>

const AppStateContext = createContext<AppContext>(defaultContext)
const AppDispatchContext = createContext<Dispatch | undefined>(undefined)

function AppContextReducer(state: AppContext, action: Action): AppContext {
  switch (action.type) {
    case 'set': {
      return { ...state, ...action.payload }
    }
  }
}

const AppContextProvider = ({ children }: PropsWithChildren) => {
  const [state, dispatch] = useReducer(AppContextReducer, defaultContext)

  const dispatchAsync = useCallback(
    async (action: Action, asyncFn?: () => Promise<any>) => {
      dispatch(action)
      return asyncFn && asyncFn()
    },
    []
  )

  return (
    <AppStateContext.Provider value={state}>
      <AppDispatchContext.Provider value={dispatchAsync}>
        {children}
      </AppDispatchContext.Provider>
    </AppStateContext.Provider>
  )
}

function useAppContextState() {
  const context = useContext(AppStateContext)
  if (context === undefined) {
    throw new Error(
      'useAppContextState must be used within a AppContextProvider'
    )
  }
  return context
}

function useAppContextDispatch() {
  const context = useContext(AppDispatchContext)
  if (context === undefined) {
    throw new Error(
      'useAppContextDispatch must be used within a AppContextProvider'
    )
  }
  return context
}

export { AppContextProvider, useAppContextState, useAppContextDispatch }
