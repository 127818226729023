import { EnumUserLocale } from 'modules/common/types/locale'
import { FALLBACK_LOCALE } from '_constants'
import { ConfigType } from '../types'

export const BLANK_CONFIG: ConfigType = {
  internalBankName: 'Blank',
  locales: (process.env.NEXT_PUBLIC_AVAILABLE_LOCALES || FALLBACK_LOCALE).split(
    ','
  ) as EnumUserLocale[],
  hasSubBanks: false,
  signupConfig: {
    shouldDisplayOperatedBy: false,
    shouldDisplayCommercialSolicitationOptIn: false,
    canAccessManagementOffer: false,
    canAccessQuickSignup: true,
  },
  defaultPublicOfferCount: 3,
  caravelAffiliationCode: 'BLANK',
}
