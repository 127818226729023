import { ButtonColor, ButtonSize, ButtonVariant } from './Button'

type ButtonTheme = {
  [key in ButtonVariant]: {
    base: string
    size: { [key in ButtonSize]: string }
  } & {
    [key in ButtonColor]: {
      default: string
      disabled: string
      loading?: string
    }
  }
}

export const BUTTON_THEME: ButtonTheme = {
  primary: {
    base: 'inline-flex justify-center items-center relative rounded-md font-bold border-2 border-solid appearance-none',
    size: {
      small: 'px-4 py-1 leading-5',
      large: 'px-4 py-3.5 leading-6',
    },
    none: {
      default: '',
      disabled:
        'text-button-none-disabled border-button-none-disabled cursor-default',
      loading: 'cursor-default pointer-events-none',
    },
    default: {
      default:
        'text-button-default-primary bg-button-default-primary border-button-default-primary hover:bg-button-default-primary-hover hover:border-button-default-primary-hover',
      disabled:
        'text-button-default-primary-disabled bg-button-default-primary-disabled border-button-default-primary-disabled cursor-default',
      loading: 'cursor-default pointer-events-none',
    },
    warning: {
      default:
        'text-button-warning-primary bg-button-warning-primary border-button-warning hover:bg-button-warning-primary-hover hover:border-button-warning-hover',
      disabled:
        'text-button-warning-primary-disabled bg-button-warning-primary-disabled border-button-warning-disabled cursor-default',
      loading: 'cursor-default pointer-events-none',
    },
    danger: {
      default:
        'text-button-danger-primary bg-button-danger-primary border-button-danger hover:bg-button-danger-primary-hover hover:border-button-danger-hover',
      disabled:
        'text-button-danger-primary-disabled bg-button-danger-primary-disabled border-button-danger-disabled cursor-default',
      loading: 'cursor-default pointer-events-none',
    },
    highlighted: {
      default:
        'text-button-highlighted-primary bg-button-highlighted-primary border-button-highlighted hover:bg-button-highlighted-primary-hover hover:border-button-highlighted-hover',
      disabled:
        'text-button-highlighted-primary-disabled bg-button-highlighted-primary-disabled border-button-highlighted-disabled cursor-highlighted',
      loading: 'cursor-highlighted pointer-events-none',
    },
  },
  secondary: {
    base: 'inline-flex justify-center items-center relative rounded-md font-bold leading-6 border-2 border-solid appearance-none',
    size: {
      small: 'px-4 py-1',
      large: 'px-4 py-3.5',
    },
    none: {
      default: '',
      disabled:
        'border-button-none-disabled text-button-none-disabled cursor-default',
      loading: 'cursor-default pointer-events-none',
    },
    default: {
      default:
        'hover:bg-button-default-secondary-hover border-button-default-secondary hover:border-button-default-secondary-hover text-button-default-secondary hover:text-button-default-secondary-hover',
      disabled:
        'border-button-default-secondary-disabled text-button-default-secondary-disabled cursor-default',
      loading: 'cursor-default pointer-events-none',
    },
    warning: {
      default:
        'hover:bg-button-warning-secondary-hover border-button-warning hover:border-button-warning-hover text-button-warning-secondary hover:text-button-warning-secondary-hover',
      disabled:
        'border-button-warning-disabled text-button-warning-secondary-disabled cursor-default',
      loading: 'cursor-default pointer-events-none',
    },
    danger: {
      default:
        'hover:bg-button-danger-secondary-hover border-button-danger hover:border-button-danger-hover text-button-danger-secondary hover:text-button-danger-secondary-hover',
      disabled:
        'border-button-danger-disabled text-button-danger-secondary-disabled cursor-default',
      loading: 'cursor-default pointer-events-none',
    },
    highlighted: {
      default:
        'hover:bg-button-highlighted-secondary-hover border-button-highlighted hover:border-button-highlighted-hover text-button-highlighted-secondary hover:text-button-highlighted-secondary-hover',
      disabled:
        'border-button-highlighted-disabled text-button-highlighted-secondary-disabled cursor-default',
      loading: 'cursor-default pointer-events-none',
    },
  },
  tertiary: {
    base: 'inline-flex justify-center items-center relative font-bold leading-6 appearance-none',
    size: {
      small: 'px-4 py-1',
      large: 'px-4 py-3.5',
    },
    none: {
      default: '',
      disabled: 'text-button-none-disabled cursor-default',
      loading: 'cursor-default pointer-events-none',
    },
    default: {
      default:
        'text-button-default-tertiary hover:text-button-default-tertiary-hover',
      disabled: 'text-button-default-tertiary-disabled cursor-default',
      loading: 'cursor-default pointer-events-none',
    },
    warning: {
      default:
        'text-button-warning-tertiary hover:text-button-warning-tertiary-hover',
      disabled: 'text-button-warning-tertiary-disabled cursor-default',
      loading: 'cursor-default pointer-events-none',
    },
    danger: {
      default:
        'text-button-danger-tertiary hover:text-button-danger-tertiary-hover',
      disabled: 'text-button-danger-tertiary-disabled cursor-default',
      loading: 'cursor-default pointer-events-none',
    },
    highlighted: {
      default:
        'text-button-highlighted-tertiary hover:text-button-highlighted-tertiary-hover',
      disabled: 'text-button-highlighted-tertiary-disabled cursor-default',
      loading: 'cursor-default pointer-events-none',
    },
  },
  icon: {
    base: 'inline-flex justify-center items-center items-center relative rounded-md font-bold border-2 border-solid appearance-none',
    size: {
      small: '',
      large: '',
    },
    none: {
      default: '',
      disabled:
        'border-button-none-disabled text-button-none-disabled cursor-default',
      loading: 'cursor-default pointer-events-none',
    },
    default: {
      default:
        'border-button-default-secondary hover:border-button-default-secondary-hover text-button-default-secondary hover:text-button-default-secondary-hover',
      disabled:
        'border-button-default-secondary-disabled text-button-default-secondary-disabled cursor-default',
      loading: 'cursor-default pointer-events-none',
    },
    warning: {
      default:
        'border-button-warning hover:border-button-warning-hover text-button-warning-secondary hover:text-button-warning-secondary-hover',
      disabled:
        'border-button-warning-disabled text-button-warning-secondary-disabled cursor-default',
      loading: 'cursor-default pointer-events-none',
    },
    danger: {
      default:
        'border-button-danger hover:border-button-danger-hover text-button-danger-secondary hover:text-button-danger-secondary-hover',
      disabled:
        'border-button-danger-disabled text-button-danger-secondary-disabled cursor-default',
      loading: 'cursor-default pointer-events-none',
    },
    highlighted: {
      default:
        'border-button-highlighted hover:border-button-highlighted-hover text-button-highlighted-secondary hover:text-button-highlighted-secondary-hover',
      disabled:
        'border-button-highlighted-disabled text-button-highlighted-secondary-disabled cursor-default',
      loading: 'cursor-default pointer-events-none',
    },
  },
  none: {
    base: 'relative leading-5 appearance-none',
    size: {
      small: '',
      large: '',
    },
    none: {
      default: '',
      disabled: 'text-button-none-disabled cursor-default',
      loading: 'cursor-default pointer-events-none',
    },
    default: {
      default:
        'text-button-default-tertiary hover:text-button-default-tertiary-hover',
      disabled: 'text-button-default-tertiary-disabled cursor-default',
      loading: 'cursor-default pointer-events-none',
    },
    warning: {
      default:
        'text-button-warning-tertiary hover:text-button-warning-tertiary-hover',
      disabled: 'text-button-warning-tertiary-disabled cursor-default',
      loading: 'cursor-default pointer-events-none',
    },
    danger: {
      default:
        'text-button-danger-tertiary hover:text-button-danger-tertiary-hover',
      disabled: 'text-button-danger-tertiary-disabled cursor-default',
      loading: 'cursor-default pointer-events-none',
    },
    highlighted: {
      default:
        'text-button-highlighted-tertiary hover:text-button-highlighted-tertiary-hover',
      disabled: 'text-button-highlighted-tertiary-disabled cursor-default',
      loading: 'cursor-default pointer-events-none',
    },
  },
}
