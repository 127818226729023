import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { Auth0Error } from 'modules/auth/types/auth0'
import {
  postRefreshToken,
  PostRefreshTokenProps,
} from '../api/postRefreshToken'

interface Props {
  mutationOptions?: UseMutationOptions<
    void,
    AxiosError<Auth0Error>,
    PostRefreshTokenProps
  >
}

export const usePostRefreshTokenMutation = ({
  mutationOptions,
}: Props = {}) => {
  return useMutation<void, AxiosError<Auth0Error>, PostRefreshTokenProps>({
    mutationFn: postRefreshToken,
    ...mutationOptions,
  })
}
