import { useState } from 'react'

export interface TabProps {
  onClick: () => void
  href?: string
  isActive: boolean
  disabled?: boolean
  title?: string
}

interface TabOption {
  disabled?: boolean
}

export function useTabs<T = string>(defaultActiveTab: T) {
  const [activeTab, setActiveTab] = useState<T>(defaultActiveTab)

  const registerTab = (value: T, options?: TabOption): TabProps => {
    return {
      onClick: () => {
        !options?.disabled && setActiveTab(value)
      },
      isActive: value === activeTab,
      ...options,
    }
  }
  return {
    activeTab,
    registerTab,
    setActiveTab,
  }
}
