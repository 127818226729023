import { BackgroundIconColor, BackgroundIconVariant } from './BackgroundIcon'
import { BACKGROUND_ICON_THEME_VARIANT } from './BackgroundIcon.theme'

interface Props {
  variant: BackgroundIconVariant
  color: BackgroundIconColor
}

export const useBackgroundIconTheme = ({ variant, color }: Props) => {
  return { themeClassName: BACKGROUND_ICON_THEME_VARIANT[variant]?.[color] }
}
