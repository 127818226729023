import { mergeClasses } from '@blank/utilities'
import {
  IllustrationName,
  IllustrationSize,
} from 'modules/common/types/illustrations'
import { Image } from '../Image/Image'

export type Props = {
  name: IllustrationName
  className?: string
} & (
  | {
      size: IllustrationSize
      width?: never
      height?: never
    }
  | {
      size?: never
      width: `${number}`
      height: `${number}`
    }
)

export const ILLUSTRATION_SIZES_MAP: Record<IllustrationSize, `${number}`> = {
  xl: '170',
  l: '136',
  m: '112',
  s: '88',
}

export const Illustration = ({
  size,
  width,
  height,
  name,
  className,
}: Props) => {
  const imagePath = `/assets/illustrations/${name}.png`

  return (
    <Image
      alt={name}
      width={size ? ILLUSTRATION_SIZES_MAP[size] : width}
      height={size ? ILLUSTRATION_SIZES_MAP[size] : height}
      src={imagePath}
      className={mergeClasses('inline', className)}
      priority
    />
  )
}
