import { Spinner } from 'modules/common/components/_ui/Spinner/Spinner'
import { useTranslation } from 'modules/common/hooks/useTranslation'

export const FullPageLoader = () => {
  const { t } = useTranslation('common')

  return (
    <div className="absolute left-0 top-0 flex min-h-screen w-full flex-col items-center justify-center space-y-2">
      <p className="text-lg">{t('loadingInProgress')}</p>
      <Spinner />
    </div>
  )
}
