import { EnumCorporateStatus } from '@blank/api'
import { useQueryWithAuth } from 'modules/auth/hooks/useQueryWithAuth'
import { QueryOptions } from 'modules/auth/types/queries'
import { fetchCorporateStatus } from '../api/fetchCorporateStatus'
import { EnumCorporateQueryKeys } from './keys'

export const useCorporateStatusQuery = (
  corporateId?: string,
  queryOptions?: QueryOptions<EnumCorporateStatus, Error>
) => {
  return useQueryWithAuth<EnumCorporateStatus, Error>({
    queryKey: [EnumCorporateQueryKeys.CORPORATES_STATUS, corporateId],
    queryFn: async (_context, accessToken) => {
      if (!corporateId) {
        throw new Error(
          'useCorporateStatusQuery: Missing parameter corporateId'
        )
      }

      return fetchCorporateStatus({ corporateId, accessToken })
    },
    enabled: !!corporateId,
    ...queryOptions,
  })
}
