import { EnumBrand } from '@blank/api'
import { UserTrackingProperties } from 'modules/common/types/tracking'
import { TrackingEvent } from '../amplitude/events'

export const pushEvent = (event: TrackingEvent) => {
  window.dataLayer?.push({
    event: 'trackEvent',
    eventName: event,
  })
}

export const pushGTMProperties = (properties: UserTrackingProperties) =>
  window.dataLayer?.push(properties)

export const resetGTMProperties = () => {
  const properties: Required<UserTrackingProperties> = {
    corporateId: null,
    email: null,
    affilae_click_id: null,
    awin_click_id: null,
    gclid: null,
    msclkid: null,
    fbclid: null,
    activityCode: null,
    activityLabel: null,
    activitySector: null,
    isPhysicalPerson: null,
    legalFormCode: null,
    createdAt: null,
    status: null,
    auth0Id: null,
    brand: process.env.NEXT_PUBLIC_BRAND as EnumBrand,
    utmSource: null,
    utmCampaign: null,
    utmMedium: null,
    offerId: null,
    country: null,
  }
  window.dataLayer?.push(properties)
}
