import { SupportModalContent } from 'modules/common/components/SupportModalContent/SupportModalContent'
import { SupportModalContentSkeleton } from 'modules/common/components/SupportModalContent/SupportModalContentSkeleton'
import { useTranslation } from 'modules/common/hooks/useTranslation'
import { useSupportTouchpoints } from '../../hooks/useSupportTouchpoints'
import {
  ContentModalProps,
  useContentModal,
} from '../useContentModal/useContentModal'
import { useFileComplaintFormModal } from '../useFileComplaintFormModal/useFileComplaintFormModal'

const useSupportModal = (modalOptions?: Partial<ContentModalProps>) => {
  const { i18n, t } = useTranslation('common-components')

  const { supportTouchpoints, isLoading } = useSupportTouchpoints()
  const { showModal: showFileComplaintModal } = useFileComplaintFormModal()

  const onFileComplaintButtonClick = () => {
    hideSupportModal()
    showFileComplaintModal()
  }

  const content = isLoading ? (
    <SupportModalContentSkeleton />
  ) : (
    <SupportModalContent
      supportTouchpoints={supportTouchpoints}
      onFileComplaintButtonClick={onFileComplaintButtonClick}
    />
  )

  const { showModal, hideModal: hideSupportModal } = useContentModal({
    width: 'medium',
    headerTitle: t('supportModal.title'),
    content,
    i18n,
    ...modalOptions,
  })

  return {
    showModal,
    hideModal: hideSupportModal,
  }
}

export default useSupportModal
