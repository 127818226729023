import { TokenUserApi } from '@blank/api'
import { EnumCookieKeys } from '_constants'
import { TokenSet } from 'auth0'
import { serialize } from 'cookie'
import jwtDecode from 'jwt-decode'
import { AuthenticatedUser } from 'modules/auth/types/auth0'
import cookieStorage from 'modules/common/lib/storage/cookieStorage'

export const getAccessToken = () => {
  return cookieStorage.retrieve(EnumCookieKeys.AUTH_ACCESS_TOKEN)
}

export const clearAccessToken = () => {
  cookieStorage.clear(EnumCookieKeys.AUTH_ACCESS_TOKEN)
}

export const clearRefreshToken = () => {
  cookieStorage.clear(EnumCookieKeys.AUTH_REFRESH_TOKEN)
}

export const assertAccessTokenIsNotExpired = (token: TokenUserApi) => {
  if (!token.exp) {
    return false
  }
  const currentTime = Date.now() / 1000
  return currentTime <= token.exp
}

export const isAccessTokenExpired = (accessToken: string) => {
  if (!accessToken) {
    return true
  }
  const jwtToken = jwtDecode<TokenUserApi>(accessToken)
  return !assertAccessTokenIsNotExpired(jwtToken)
}

export const getAuthenticatedUser = (token: string): AuthenticatedUser => {
  const tokenUser = jwtDecode<TokenUserApi>(token)

  return {
    firstname: tokenUser['https://blank.app/firstname'],
    lastname: tokenUser['https://blank.app/lastname'],
    corporateId: tokenUser['https://blank.app/corporateId'],
    email: tokenUser['https://blank.app/email'],
    emailVerified: tokenUser['https://blank.app/email_verified'],
    sub: tokenUser.sub,
    roles: tokenUser['https://blank.app/roles'],
    phoneNumber: tokenUser['https://blank.app/phoneNumber'],
    phoneNumberPending: tokenUser['https://blank.app/phoneNumberPending'],
    phoneNumberVerified: tokenUser['https://blank.app/phoneNumberVerified'],
    hasMFA: tokenUser['https://blank.app/hasMFA'],
    hasCompletedSca: tokenUser['https://blank.app/hasCompletedSCA'], // has a level 2 token (auth verified by SCA)
    isEnrolled: tokenUser['https://blank.app/isEnrolled'], // has enrolled a SCA device
    actionId: tokenUser['https://blank.app/actionId'], // sca action id
    country: tokenUser['https://blank.app/country'],
  }
}

export const buildAuthCookies = (token: TokenSet) => {
  return [
    serialize(EnumCookieKeys.AUTH_ACCESS_TOKEN, token.access_token, {
      path: '/',
      secure: true,
    }),
    ...(token.refresh_token
      ? [
          serialize(EnumCookieKeys.AUTH_REFRESH_TOKEN, token.refresh_token, {
            path: '/',
            secure: true,
            httpOnly: true,
          }),
        ]
      : []),
  ]
}

export const clearAuthCookies = () => {
  return [
    serialize(EnumCookieKeys.AUTH_ACCESS_TOKEN, '', {
      maxAge: -1,
      secure: true,
      path: '/',
    }),
    serialize(EnumCookieKeys.AUTH_REFRESH_TOKEN, '', {
      maxAge: -1,
      secure: true,
      path: '/',
    }),
  ]
}
