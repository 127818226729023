import { mergeClasses } from '@blank/utilities'
import { IconCheckRounded } from 'modules/common/components/_icons/icons'
import { Trans } from 'react-i18next'

export const BULLET_POINTS_LIST_TEXT_SIZE = ['default', 'small'] as const

export interface BulletPointsListProps {
  labels: string[]
  icons?: React.ReactNode[]
  className?: string
  textSize?: (typeof BULLET_POINTS_LIST_TEXT_SIZE)[number]
}
export const BulletPointsList = ({
  labels,
  icons,
  className,
  textSize = 'default',
}: BulletPointsListProps) => {
  return (
    <ul className={mergeClasses('space-y-4', className)}>
      {labels.map((label, index) => (
        <li className="flex items-center space-x-4" key={label}>
          <span className="flex-none text-primary">
            {icons?.[index] || <IconCheckRounded />}
          </span>
          <Trans
            parent="p"
            className={mergeClasses({
              'text-sm': textSize === 'small',
              'text-base': textSize === 'default',
            })}
          >
            {label}
          </Trans>
        </li>
      ))}
    </ul>
  )
}
