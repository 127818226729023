import { mergeClasses } from '@blank/utilities'
import { PropsWithChildren } from 'react'

export const ModalFooter = ({
  children,
  className,
}: PropsWithChildren<{ className?: string }>) => {
  return (
    <div
      className={mergeClasses(
        'bottom-0 flex w-full items-center justify-center border-t border-info-100 bg-white px-4 py-4 md:px-6',
        className
      )}
    >
      {children}
    </div>
  )
}
