import { VerticalProgressStep } from 'modules/common/components/VerticalProgressStepper/VerticalProgressStep/VerticalProgressStep'
import { EnumVerticalStepStatus } from 'modules/common/components/VerticalProgressStepper/VerticalProgressStep/VerticalProgressStep.theme'
import { VerticalProgressStepper } from 'modules/common/components/VerticalProgressStepper/VerticalProgressStepper'
import { useTranslation } from 'modules/common/hooks/useTranslation'

export const HowToOrderCardInsert = () => {
  const { t } = useTranslation('bank-components')

  const steps = t('howToOrderCardInsert.steps', {
    returnObjects: true,
  })

  return (
    <div className="space-y-4 rounded-2xl border border-info-100 p-8">
      <h3>{t('howToOrderCardInsert.title')}</h3>
      <VerticalProgressStepper className="w-full">
        {steps.map((title, index) => {
          const icons = { [EnumVerticalStepStatus.ONGOING]: index + 1 }
          return (
            <VerticalProgressStep
              key={index}
              title={title}
              status={EnumVerticalStepStatus.ONGOING}
              icons={icons}
            />
          )
        })}
      </VerticalProgressStepper>
    </div>
  )
}
