import { EnumUserCountry } from '@blank/api'
import axios from 'axios' // eslint-disable-line no-restricted-imports

export const COUNTRY_SCA_API_BASE_URL_MAP: Record<EnumUserCountry, string> = {
  [EnumUserCountry.FRA]: process.env.NEXT_PUBLIC_SCA_API_URL || '',
  [EnumUserCountry.ITA]: process.env.NEXT_PUBLIC_SCA_IT_API_URL || '',
}

export const scaApiClient = axios.create({
  baseURL: process.env.NEXT_PUBLIC_SCA_API_URL,
})
