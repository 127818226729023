import { useAuthenticatedUserCorporateQuery } from 'modules/auth/queries/useAuthenticatedUserCorporateQuery'
import { useCountryConfig } from 'modules/common/hooks/useCountryConfig'
import { ContentModalProps } from 'modules/common/modals/useContentModal/useContentModal'
import { useTypeformModal } from 'modules/common/modals/useTypeformModal/useTypeformModal'
import { useFileComplaintFormSuccessModal } from './useFileComplaintFormSuccessModal'

export const useFileComplaintFormModal = (
  props?: Omit<ContentModalProps, 'content'>
) => {
  const {
    data: { corporate, user },
    isLoading,
    isError,
    errorRefetch,
  } = useAuthenticatedUserCorporateQuery()

  const { showModal: showSuccessModal } = useFileComplaintFormSuccessModal()

  const { fileComplaintTypeformId } = useCountryConfig()

  return useTypeformModal({
    formId: fileComplaintTypeformId,
    onTypeformSubmitted: showSuccessModal,
    overlayTransitionType: 'transition-none',
    hiddenFields: {
      is_client: corporate?.globalStatusValidatedAt ? 'true' : 'false',
      email: user?.email || '',
      phone: user?.phoneNumber || '',
      first_name: corporate?.requester?.firstName || '',
      last_name: corporate?.requester?.lastName || '',
      corporate_id: corporate?.id || '',
    },
    isLoading,
    error: { isError, errorRefetch },
    ...props,
  })
}
