import { AccountApi, ProviderApi } from '@blank/api'
import { AccountWithProvider } from 'modules/bank/types'
import { sortAccounts } from 'modules/common/lib/sort/customSort'

export const buildAccountsForIbanSelectionWithProvider = (
  accounts: AccountApi[],
  providers: ProviderApi[]
): AccountWithProvider[] =>
  sortAccounts(accounts)
    .filter(({ iban }) => !!iban)
    .map((account) => ({
      ...account,
      provider: providers.find(({ id }) => id === account.providerId),
    }))

const formatAccountName = (accountName: string | undefined) =>
  accountName?.replace('Migrate-', '')

export const mapAccountToAccountWithFormattedName = (account: AccountApi) => {
  return {
    ...account,
    name: formatAccountName(account.name),
  }
}
