import { FetchAccountsResponseApi } from '@blank/api'
import { useQueryWithAuth } from 'modules/auth/hooks/useQueryWithAuth'
import { QueryOptions } from 'modules/auth/types/queries'
import { fetchAccounts } from '../api/fetchAccounts'
import { EnumBankQueryKeys } from './keys'

export const useAccountsQuery = (
  queryOptions?: QueryOptions<FetchAccountsResponseApi, Error>
) => {
  return useQueryWithAuth<FetchAccountsResponseApi, Error>({
    queryKey: [EnumBankQueryKeys.ACCOUNTS_LIST],
    queryFn: async (_context, accessToken) => fetchAccounts({ accessToken }),
    ...queryOptions,
  })
}
