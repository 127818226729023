import { mergeClasses } from '@blank/utilities'
import { useMemo } from 'react'
import { ButtonColor, ButtonSize, ButtonVariant } from './Button'
import { BUTTON_THEME } from './Button.theme'

interface Props {
  variant: ButtonVariant
  color: ButtonColor | undefined
  disabled?: boolean
  isLoading?: boolean
  size: ButtonSize
}

export const useButtonTheme = ({
  variant,
  color = 'none',
  disabled,
  isLoading,
  size = 'large',
}: Props) => {
  const buttonClasses = useMemo(() => {
    return mergeClasses(
      BUTTON_THEME[variant].base,
      BUTTON_THEME[variant].size[size],
      {
        [BUTTON_THEME[variant][color]['default']]: !!color && !disabled,
        [BUTTON_THEME[variant][color]['loading'] || '']: !!color && isLoading,
        [BUTTON_THEME[variant][color]['disabled']]:
          !!color && disabled && !isLoading,
      }
    )
  }, [variant, size, color, disabled, isLoading])

  return { buttonClasses }
}
