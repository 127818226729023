import { mergeClasses } from '@blank/utilities'
import { Skeleton, SkeletonProps } from '../Skeleton/Skeleton'

export const ButtonSkeleton = ({ className, ...props }: SkeletonProps) => {
  return (
    <Skeleton
      className={mergeClasses('h-14 w-24 rounded-md', className)}
      {...props}
    />
  )
}
