import { DefaultNamespace, KeyPrefix, Namespace, TFunction } from 'i18next'

import _ from 'lodash'
import { FallbackNs, UseTranslationOptions } from 'react-i18next'
import { useAppContextState } from '../contexts/AppContext'
// eslint-disable-next-line no-restricted-imports
import { useTranslation as usei18nTranslation } from 'next-i18next'
import { useMemo } from 'react'

function getNamespace<N>({
  key,
  ns,
  defaultNS,
}: {
  key: string | TemplateStringsArray
  ns: string | N | undefined
  defaultNS: string
}) {
  const resultFromSplit = String(key).split(':')

  if (resultFromSplit.length > 1) {
    return { namespace: resultFromSplit[0], actualKey: resultFromSplit[1] }
  }

  return { namespace: ns || defaultNS, actualKey: key }
}

export function useTranslation<
  N extends Namespace = DefaultNamespace,
  KPrefix extends KeyPrefix<FallbackNs<N>> = undefined,
>(ns?: N, options?: UseTranslationOptions<KPrefix>) {
  const { t, ...useTranslationResponse } = usei18nTranslation<N, KPrefix>(
    ns,
    options
  )
  const { brand } = useAppContextState()

  const { defaultNS } = useTranslationResponse.i18n.options || {}

  const translate = useMemo((): TFunction<FallbackNs<N>, KPrefix> => {
    const tFunction = (
      ...args: Parameters<TFunction<FallbackNs<N>, KPrefix>>
    ): ReturnType<TFunction<FallbackNs<N>, KPrefix>> => {
      const [key, ...tOtherArgs] = args
      const keysArray = _.isArray(key) ? key : [key]

      const namespaceFromHook = _.isArray(ns) ? ns[0] : ns
      const defaultNamespace = _.isArray(defaultNS)
        ? (defaultNS[0] as string)
        : (defaultNS as string)

      const keysWithBrand = []

      for (const k of keysArray) {
        const { namespace, actualKey } = getNamespace<N>({
          key: k,
          ns: namespaceFromHook,
          defaultNS: defaultNamespace,
        })

        const keyWithNamespace = `${namespace}:${namespace}.${actualKey}`

        // We push both keys with and without brand.
        // If key with brand does not exist, i18next will fallback to key without brand.
        keysWithBrand.push(`${keyWithNamespace}%${brand}%`)
        keysWithBrand.push(keyWithNamespace)
      }

      const modifiedArgs = [keysWithBrand, ...tOtherArgs] as Parameters<
        TFunction<FallbackNs<N>, KPrefix>
      >

      return t(...modifiedArgs)
    }

    tFunction.$TFunctionBrand = t.$TFunctionBrand
    return tFunction as TFunction<FallbackNs<N>, KPrefix>
  }, [brand, defaultNS, ns, t])

  return {
    ...useTranslationResponse,
    t: translate,
  }
}
