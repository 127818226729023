import { EnumBillingServiceId } from '@blank/api'
import { useCountryConfig } from 'modules/common/hooks/useCountryConfig'
import { useCorporateOfferServices } from 'modules/corporate/hooks/useCorporateOfferServices'
import { SupportTouchpoints } from '../types/support'
import { useCountryExternalLinks } from './useCountryExternalLinks'

export const useSupportTouchpoints = () => {
  const { isLoading, services } = useCorporateOfferServices()
  const externalLinks = useCountryExternalLinks()
  const { whatsAppSupportNumber } = useCountryConfig()

  const phoneSupport = services?.find(
    (service) => service.id === EnumBillingServiceId.PHONE_SUPPORT
  )?.phone

  const emailSupport = externalLinks.supportEmail

  const faqURL = externalLinks.faq

  const supportTouchpoints: SupportTouchpoints = {
    phoneSupport,
    emailSupport,
    whatsAppSupportNumber,
    faqURL,
  }

  return {
    supportTouchpoints,
    isLoading,
  }
}
