import { externalLinkProps } from 'modules/common/lib/helpers/externalLinkProps'
import NextLink from 'next/link' // eslint-disable-line no-restricted-imports
import { PropsWithChildren } from 'react'
import { UrlObject } from 'url'

interface Props extends PropsWithChildren {
  className?: string
  href: string | UrlObject
  title?: string
  onClick?: () => void
  'data-testid'?: string
}

export const Link = ({
  children,
  className,
  href,
  title,
  onClick,
  'data-testid': dataTestId,
}: Props) => {
  const isExternalLink = typeof href === 'string' && !/^\/\w/.test(href)

  return (
    <NextLink
      href={href}
      className={className}
      title={title}
      data-testid={dataTestId}
      onClick={onClick}
      {...(isExternalLink && externalLinkProps)}
    >
      {children}
    </NextLink>
  )
}
