import { EnumOfferType } from '@blank/api'
import { useCorporatePaymentMethodsQuery } from 'modules/billing/queries/useCorporatePaymentMethodsQuery'
import { useCorporateLifecycleQueries } from 'modules/corporate/hooks/useCorporateLifecycleQueries'
import { useCorporateOfferQuery } from 'modules/corporate/queries/useCorporateOfferQuery'
import { useCallback } from 'react'

import { useInternalAccount } from 'modules/bank/hooks/account/useInternalAccount'
import { useAuthenticatedUserQuery } from '../queries/useAuthenticatedUserQuery'

export const useRequiredAuthDataQueries = () => {
  const { data: user } = useAuthenticatedUserQuery()

  const {
    data: { isKycComplete, corporate },
    isLoading: isCorporateLifecycleLoading,
    isError: isCorporateLifecycleError,
    errorRefetch: refetchCorporateLifecycle,
  } = useCorporateLifecycleQueries()

  const {
    data: corporateOffer,
    isLoading: isCorporateOfferLoading,
    isError: isCorporateOfferError,
    refetch: refetchCorporateOffer,
  } = useCorporateOfferQuery(user?.corporateId)

  const {
    data: corporatePaymentMethods,
    isLoading: isCorporatePaymentMethodsLoading,
    isError: isCorporatePaymentMethodsError,
    refetch: refetchCorporatePaymentMethods,
  } = useCorporatePaymentMethodsQuery(corporate?.id, {
    enabled:
      !!corporate?.id &&
      corporateOffer?.offerType === EnumOfferType.STRICTLY_MANAGEMENT_TOOLS,
  })

  const {
    data: internalAccount,
    isLoading: isInternalAccountLoading,
    isError: isInternalAccountError,
    errorRefetch: refetchInternalAccount,
  } = useInternalAccount({ enabled: !!user })

  const errorRefetch = useCallback(() => {
    if (isCorporateLifecycleError) {
      refetchCorporateLifecycle()
    }
    if (isCorporateOfferError) {
      refetchCorporateOffer()
    }
    if (isCorporatePaymentMethodsError) {
      refetchCorporatePaymentMethods()
    }
    if (isInternalAccountError) {
      refetchInternalAccount()
    }
  }, [
    isCorporateLifecycleError,
    isCorporateOfferError,
    isCorporatePaymentMethodsError,
    isInternalAccountError,
    refetchCorporateLifecycle,
    refetchCorporateOffer,
    refetchCorporatePaymentMethods,
    refetchInternalAccount,
  ])

  return {
    data: {
      isKycComplete,
      user,
      corporate,
      corporateOffer,
      corporatePaymentMethods,
      internalAccount,
    },
    isUserAuthenticated: !!user,
    isFetchLoading:
      isCorporateLifecycleLoading ||
      isCorporateOfferLoading ||
      isCorporatePaymentMethodsLoading ||
      isInternalAccountLoading,
    isFetchError:
      isCorporateLifecycleError ||
      isCorporateOfferError ||
      isCorporatePaymentMethodsError ||
      isInternalAccountError,
    errorRefetch,
  }
}
