import { mergeClasses } from '@blank/utilities'
import { PropsWithChildren } from 'react'
import {
  EnumVerticalStepStatus,
  VERTICAL_PROGRESS_STEPPER_DEFAULT_ICONS,
  VERTICAL_PROGRESS_STEPPER_THEME,
} from './VerticalProgressStep.theme'

interface Props {
  status: EnumVerticalStepStatus
  className?: string
  description?: string
  icons?: Partial<Record<EnumVerticalStepStatus, number | JSX.Element>>
  title?: string | JSX.Element
}

export const VerticalProgressStep = ({
  children,
  className,
  description,
  icons,
  title,
  status,
}: PropsWithChildren<Props>) => {
  const icon =
    icons?.[status] ?? VERTICAL_PROGRESS_STEPPER_DEFAULT_ICONS[status]

  const { connectorTheme, descriptionTheme, iconTheme, titleTheme } =
    VERTICAL_PROGRESS_STEPPER_THEME[status]

  return (
    <div
      className={mergeClasses(
        'group flex min-h-14 gap-4 last:min-h-fit',
        className
      )}
    >
      <div className="flex shrink-0 flex-col items-center gap-1">
        <div
          className={mergeClasses(
            'flex h-9 w-9 shrink-0 items-center justify-center rounded-full text-white',
            iconTheme
          )}
        >
          {typeof icon === 'number' ? (
            <h3 className="text-white">{icon}</h3>
          ) : (
            icon
          )}
        </div>
        <div
          className={mergeClasses(
            'h-full w-0 border group-last:border-0',
            connectorTheme
          )}
        />
      </div>
      <div className="grow space-y-4 pb-4 pt-2 group-last:pb-0">
        {(title || description) && (
          <div className="space-y-1">
            {title && (
              <p className={mergeClasses('font-bold', titleTheme)}>{title}</p>
            )}
            {description && <p className={descriptionTheme}>{description}</p>}
          </div>
        )}
        {children}
      </div>
    </div>
  )
}
