import { SpinnerProps } from '../Spinner/Spinner'
import { ButtonColor, ButtonVariant } from './Button'

type SpinnerTheme = {
  [key in ButtonVariant]?: { className: string } & Partial<{
    [key in ButtonColor]: Partial<
      Pick<SpinnerProps, 'circleClassName' | 'circleBgClassName'>
    >
  }>
} & { baseClassName: string }

export const SPINNER_THEME: SpinnerTheme = {
  baseClassName: 'shrink-0',
  primary: {
    className: 'absolute',
    none: {
      circleClassName: 'text-button-spinner-none',
      circleBgClassName: 'text-button-spinner-none-background',
    },
    default: {
      circleClassName: 'text-button-spinner-default-primary',
      circleBgClassName: 'text-button-spinner-default-primary-background',
    },
    warning: {
      circleClassName: 'text-button-spinner-warning-primary',
      circleBgClassName: 'text-button-spinner-warning-primary-background',
    },
    danger: {
      circleClassName: 'text-button-spinner-danger-primary',
      circleBgClassName: 'text-button-spinner-danger-primary-background',
    },
    highlighted: {
      circleClassName: 'text-button-spinner-highlighted-primary',
      circleBgClassName: 'text-button-spinner-highlighted-primary-background',
    },
  },
  secondary: {
    className: 'absolute',
    none: {
      circleClassName: 'text-button-spinner-none',
      circleBgClassName: 'text-button-spinner-none-background',
    },
    default: {
      circleClassName: 'text-button-spinner-default-secondary',
      circleBgClassName: 'text-button-spinner-default-secondary-background',
    },
    warning: {
      circleClassName: 'text-button-spinner-warning-secondary',
      circleBgClassName: 'text-button-spinner-warning-secondary-background',
    },
    danger: {
      circleClassName: 'text-button-spinner-danger-secondary',
      circleBgClassName: 'text-button-spinner-danger-secondary-background',
    },
    highlighted: {
      circleClassName: 'text-button-spinner-highlighted-secondary',
      circleBgClassName: 'text-button-spinner-highlighted-secondary-background',
    },
  },
  icon: {
    className: 'absolute',
    none: {
      circleClassName: 'text-button-spinner-none',
      circleBgClassName: 'text-button-spinner-none-background',
    },
    default: {
      circleClassName: 'text-button-spinner-default-secondary',
      circleBgClassName: 'text-button-spinner-default-secondary-background',
    },
    warning: {
      circleClassName: 'text-button-spinner-warning-secondary',
      circleBgClassName: 'text-button-spinner-warning-secondary-background',
    },
    danger: {
      circleClassName: 'text-button-spinner-danger-secondary',
      circleBgClassName: 'text-button-spinner-danger-secondary-background',
    },
    highlighted: {
      circleClassName: 'text-button-spinner-highlighted-secondary',
      circleBgClassName: 'text-button-spinner-highlighted-secondary-background',
    },
  },
}
