import { EnumSupportModalTabs } from '../types/support'
import { useTabs } from './useTabs'

export const useSupportModalTabs = () => {
  const { registerTab, activeTab } = useTabs(EnumSupportModalTabs.HELP)
  const tabs = Object.values(EnumSupportModalTabs)

  return {
    tabs,
    registerTab,
    activeTab,
  }
}
