import { useQuery } from '@tanstack/react-query'
import { FreshTokenOptions, useFreshToken } from '../hooks/useFreshToken'
import { getAccessToken, getAuthenticatedUser } from '../lib/auth0/token'
import { AuthenticatedUser } from '../types/auth0'
import { QueryOptions } from '../types/queries'
import { EnumAuthQueryKeys } from './keys'

export const useAuthenticatedUserQuery = (
  queryOptions?: QueryOptions<AuthenticatedUser | null, Error>,
  getFreshTokenOptions?: FreshTokenOptions
) => {
  const getFreshToken = useFreshToken()
  const token = getAccessToken()

  return useQuery({
    queryKey: [EnumAuthQueryKeys.AUTHENTICATED_USER],

    queryFn: async () => {
      const accessToken = await getFreshToken(getFreshTokenOptions)

      if (!accessToken) {
        throw new Error('useAuthenticatedUserQuery: Token is missing')
      }

      return getAuthenticatedUser(accessToken)
    },
    retry: false,
    enabled: !!token,
    placeholderData: token ? getAuthenticatedUser(token) : null,
    refetchOnWindowFocus: false,
    ...queryOptions,
  })
}
