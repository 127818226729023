import { CorporatePaymentServiceProviderCustomerApi } from '@blank/api'
import { useQueryWithAuth } from 'modules/auth/hooks/useQueryWithAuth'
import { QueryOptions } from 'modules/auth/types/queries'
import { fetchCorporatePaymentMethods } from '../api/fetchCorporatePaymentMethods'
import { EnumBillingQueryKeys } from './keys'

export const useCorporatePaymentMethodsQuery = (
  corporateId: string | undefined,
  queryOptions?: QueryOptions<
    CorporatePaymentServiceProviderCustomerApi[],
    Error
  >
) => {
  return useQueryWithAuth<CorporatePaymentServiceProviderCustomerApi[], Error>({
    queryKey: [EnumBillingQueryKeys.CORPORATE_PAYMENT_METHODS],
    queryFn: async (_context, accessToken) => {
      if (!corporateId) {
        throw new Error(
          'useCorporatePaymentMethodsQuery: Missing parameter corporateId'
        )
      }

      return fetchCorporatePaymentMethods({ corporateId, accessToken })
    },
    enabled: Boolean(corporateId),
    ...queryOptions,
  })
}
