import Cookies from 'js-cookie'
import { USER_LOCALE_MAP } from 'modules/common/types/locale'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { EnumCookieKeys } from '_constants'
import { useAuthenticatedUserQuery } from '../queries/useAuthenticatedUserQuery'

export const useLocaleRedirection = () => {
  const router = useRouter()

  const { data: authenticatedUser } = useAuthenticatedUserQuery()

  useEffect(() => {
    if (authenticatedUser?.country) {
      Cookies.set(
        EnumCookieKeys.NEXT_LOCALE,
        USER_LOCALE_MAP[authenticatedUser.country]
      )

      if (router.locale !== USER_LOCALE_MAP[authenticatedUser.country]) {
        const { asPath, pathname, query } = router

        // Replace with the same route, only changing the locale
        // according to the user's
        router.replace({ pathname, query }, asPath, {
          locale: USER_LOCALE_MAP[authenticatedUser.country],
        })
      }
    }
  }, [authenticatedUser?.country, router])
}
