import { EnumUserLocale } from 'modules/common/types/locale'

export const LEGAL_PLACE_COMPANY_SERVICE_PRICE = 99

export enum EnumLocalStorageKeys {
  INVOICE_CREATION_FORM = 'blnk-invoice-creation-form',
  ESTIMATE_CREATION_FORM = 'blnk-estimate-creation-form',
  SIGNUP_FORM = 'blnk-signup-form',
  SCA_LOGIN_ACTION = 'sca-login-action',
  // This key has been deprecated since zustand has been setup but we keep it for backwards compatibility
  BANNERS_TO_HIDE_DEPRECATED = 'blnk-banners-to-hide',
  BANNERS_TO_HIDE = 'blnk-banners-to-hide-v2',
  ONE_TIME_ANNOUNCEMENTS = 'blnk-one-time-announcements',
  FAKE_DOORS_TO_HIDE = 'blnk-fake-doors-to-hide',
  PERSISTENT_MODALS = 'blnk-persistent-modals',
}

export enum EnumCookieKeys {
  LANDING_PAGE_SETTINGS = 'blnk_landing_page_parameters',
  AUTH_ACCESS_TOKEN = 'blnk-auth0-access-token',
  AUTH_REFRESH_TOKEN = 'blnk-auth0-refresh-token',
  NEXT_LOCALE = 'NEXT_LOCALE',
}

export enum EnumFileSizeInBytes {
  /* eslint-disable @typescript-eslint/naming-convention */
  '5MB' = 5242880,
  '10MB' = 10485760,
  /* eslint-enable */
}

export const NEXT_DEFAULT_LOCALE = 'default'

export const FALLBACK_LOCALE = EnumUserLocale.FR

export const COMMON_EXTERNAL_LINKS = {
  gmail: 'https://mail.google.com/mail',
  outlook: 'https://outlook.office.com/mail',
}
