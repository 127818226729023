import { EnumBrand } from '@blank/api'
import { EnumUserLocale } from 'modules/common/types/locale'
import { BLANK_EXTERNAL_LINKS_FR } from './blank/fr'
import { BLANK_EXTERNAL_LINKS_IT } from './blank/it'
import { CA_EXTERNAL_LINKS_FR } from './ca/fr'
import { LCL_EXTERNAL_LINKS_FR } from './lcl/fr'
import { LocalizedExternalLinks } from './type'

const EXTERNAL_LINKS_BY_BRAND_MAP: Record<EnumBrand, LocalizedExternalLinks> = {
  [EnumBrand.BLANK]: {
    [EnumUserLocale.FR]: BLANK_EXTERNAL_LINKS_FR,
    [EnumUserLocale.IT]: BLANK_EXTERNAL_LINKS_IT,
  },
  [EnumBrand.CA]: {
    [EnumUserLocale.FR]: CA_EXTERNAL_LINKS_FR,
  },
  [EnumBrand.LCL]: {
    [EnumUserLocale.FR]: LCL_EXTERNAL_LINKS_FR,
  },
}

const buildTimeBrand: EnumBrand =
  (process.env.NEXT_PUBLIC_BRAND as EnumBrand) ||
  (process.env.STORYBOOK_BRAND as EnumBrand) ||
  EnumBrand.BLANK

export const EXTERNAL_LINKS: LocalizedExternalLinks =
  EXTERNAL_LINKS_BY_BRAND_MAP[buildTimeBrand]
