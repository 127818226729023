import { CorporateOfferApi } from '@blank/api'
import { useQueryWithAuth } from 'modules/auth/hooks/useQueryWithAuth'
import { QueryOptions } from 'modules/auth/types/queries'
import { fetchCorporateOffer } from '../api/fetchCorporateOffer'
import { EnumCorporateQueryKeys } from './keys'

export const useCorporateOfferQuery = (
  corporateId: string | undefined,
  queryOptions?: QueryOptions<CorporateOfferApi, Error>
) => {
  return useQueryWithAuth<CorporateOfferApi, Error>({
    queryKey: [EnumCorporateQueryKeys.CORPORATES_OFFER, corporateId],
    queryFn: async (_context, accessToken) => {
      if (!corporateId) {
        throw new Error('useCorporateOfferQuery: Missing parameter corporateId')
      }

      return fetchCorporateOffer({ corporateId, accessToken })
    },
    enabled: !!corporateId,
    ...queryOptions,
  })
}
