import { mergeClasses } from '@blank/utilities'
import { PropsWithChildren } from 'react'

interface Props {
  className?: string
}

export const VerticalProgressStepper = ({
  className,
  children,
}: PropsWithChildren<Props>) => {
  return <div className={mergeClasses('space-y-1', className)}>{children}</div>
}
