import { CorporateApi } from '@blank/api'
import { apiClient } from 'modules/common/http/apiClient'
import { getAuthorizationHeader } from 'modules/common/lib/api/getAuthorizationHeader'

interface Props {
  corporateId: string
  accessToken: string
}

export const fetchCorporate = async ({ corporateId, accessToken }: Props) => {
  const apiUrl = `/v1/users/corporates/${corporateId}`

  const { data } = await apiClient.get<CorporateApi>(apiUrl, {
    headers: getAuthorizationHeader(accessToken),
  })

  return data
}
