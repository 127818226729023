import { mergeClasses } from '@blank/utilities'
import { PropsWithChildren } from 'react'

interface Props {
  className?: string
  dataTestId?: string
}

export const Tabs = ({
  children,
  className,
  dataTestId,
}: PropsWithChildren<Props>) => {
  return (
    <div
      className={mergeClasses('flex gap-x-8 shadow-tabs', className)}
      data-testid={dataTestId}
    >
      {children}
    </div>
  )
}
