export enum EnumRoutes {
  PHONE_NUMBER_VERIFICATION = '/verification-telephone',
  HOME = '/accueil',
  SERVICES = '/services',
  MANDATORY_INFORMATION = '/information-compte',
  OFFER_SELECTION = '/selection-offre',
  OFFER_UPSELL = '/montee-en-gamme',

  // Auth Routes
  LOGIN = '/connexion',
  SCA_LOGIN = '/connexion-sca',
  SIGNUP = '/inscription',
  SIGNUP_DOMICILIATION = '/inscription/domiciliation',
  SIGNUP_INFORMATION = '/inscription/informations',
  QUICK_SIGNUP = '/inscription-rapide',
  SECURING_DEVICE = '/securisation-appareil',
  KYC_ROOT = '/ouverture-compte',
  ACCOUNT_BLOCK = '/blocage-compte',
  EMAIL_CONFIRMATION_ROUTE = '/confirmation-email',
  EMAIL_VERIFICATION = '/verification-email',
  PASSWORD_CHANGE_REQUEST = '/demande-changement-mot-de-passe',
  PASSWORD_CHANGE_CONFIRM = '/demande-changement-mot-de-passe-confirmation',

  // Accounts routes
  ACCOUNTS = '/comptes',
  ACCOUNT_CARD = '/comptes/carte',
  ACCOUNT_MANDATES = '/comptes/prelevements',
  ACCOUNT_CHECK = '/comptes/cheque',
  ACCOUNT_BENEFICIARIES = '/comptes/beneficiaires',
  ACCOUNT_TRANSFER_ORDERS = '/comptes/virements-programmes',
  ACCOUNT_NEW_TRANSFER = '/comptes/nouveau-virement',
  ACCOUNT_AVERAGE_BALANCE_DOCUMENTS = '/comptes/documents-comptables',

  // Invoicing routes
  INVOICING = '/facturation',
  INVOICING_DOCUMENTS = '/facturation/documents',
  INVOICING_DOCUMENTS_CONFIGURATION = '/facturation/documents/configuration',
  INVOICING_DOCUMENTS_SLUG = '/facturation/creation/[documentTypeSlug]',
  INVOICING_INVOICE_CREATION = '/facturation/creation/facture',
  INVOICING_ESTIMATE_CREATION = '/facturation/creation/devis',
  INVOICING_CUSTOMERS = '/facturation/clients',
  INVOICING_SETTINGS = '/facturation/parametres/mentions-generales',

  // Management offer routes
  MANAGEMENT_TOOLS_LOGIN = '/gestion-connexion',
  MANAGEMENT_TOOLS_SIGNUP = '/gestion-inscription',

  // Settings routes
  SETTINGS_HOME = '/parametres',
  SETTINGS_PROFILE_PERSONAL_INFORMATION = '/parametres/profil/informations-personnelles',
  SETTINGS_PROFILE_MY_COMPANY = '/parametres/profil/mon-entreprise',
  SETTINGS_AUTHENTICATION = '/parametres/reglages',
  SETTINGS_SUBSCRIPTION_HOME = '/parametres/mon-abonnement',
  SETTINGS_SUBSCRIPTION_OFFER = '/parametres/mon-abonnement/mon-offre',
  SETTINGS_SUBSCRIPTION_CONTRACT = '/parametres/mon-abonnement/contrat',
  SETTINGS_SUBSCRIPTION_INVOICES = '/parametres/mon-abonnement/factures',
  SETTINGS_INSURANCES = '/parametres/assurances',
  SETTINGS_SPONSORSHIP = '/parametres/parrainage',
  SETTINGS_ACCOUNT_CLOSING = '/parametres/cloture-compte',
  SETTINGS_ACCOUNT_CLOSING_CHECKLIST = '/parametres/cloture-compte/checklist',
  SETTINGS_ACCOUNT_CLOSING_TRANSACTIONS = '/parametres/cloture-compte/transactions',
  SETTINGS_ACCOUNT_CLOSING_VALIDATION = '/parametres/cloture-compte/validation',

  // Hidden routes
  HIDDEN_SET_PIN_VALIDATED = '/cache/validation-definition-pin',
}
