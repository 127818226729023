import { BulletPointsList } from 'modules/common/components/BulletPointsList/BulletPointsList'
import { Image } from 'modules/common/components/Image/Image'
import { useTranslation } from 'modules/common/hooks/useTranslation'
import { HowToOrderCardInsert } from '../HowToOrderCardInsert/HowToOrderCardInsert'

export const OrderNewTokamakCardMigrationModalContent = () => {
  const { t } = useTranslation('bank-components')

  return (
    <div className="flex flex-col items-center text-left">
      <Image
        alt="card-order"
        src="/assets/img/card-order.png"
        width="375"
        height="240"
      />
      <h2 className="pt-6 text-primary">
        {t('orderNewTokamakCardMigrationModal.title')}
      </h2>
      <p className="pt-4 font-bold">
        {t('orderNewTokamakCardMigrationModal.subtitle')}
      </p>
      <BulletPointsList
        className="py-6"
        labels={[
          t('orderNewTokamakCardMigrationModal.orderDescription'),
          t('orderNewTokamakCardMigrationModal.featuresDescription'),
          t('orderNewTokamakCardMigrationModal.migrationLimitDescription'),
        ]}
      />
      <HowToOrderCardInsert />
    </div>
  )
}
