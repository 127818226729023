import { ButtonSkeleton } from '../_ui/Skeletons/ButtonSkeleton/ButtonSkeleton'
import { Skeleton } from '../_ui/Skeletons/Skeleton/Skeleton'

export const SupportModalContentSkeleton = () => (
  <div className="flex w-full flex-col">
    <Skeleton className="mb-2 h-5 w-1/2" />
    <Skeleton className="mb-2 h-5 w-full" />
    <Skeleton className="mb-4 h-5 w-3/4" />
    <ButtonSkeleton className="mb-8 w-full min-w-full" />
    <Skeleton className="mb-2 h-4 w-full" />
    <Skeleton className="mb-2 h-4 w-1/4" />
    <Skeleton className="mb-2 h-4 w-full" />
    <Skeleton className="mb-8 h-4 w-3/4" />
    <Skeleton className="h-40 w-full rounded-2xl" />
  </div>
)
