import { useUserLocale } from 'modules/common/hooks/useUserLocale'
import { EnumUserLocale } from 'modules/common/types/locale'
// eslint-disable-next-line no-restricted-imports
import NextImage, { ImageProps as NextImageProps } from 'next/image'

// NB: We Define which folders from public/assets/[folderName] is localized
const LOCALIZED_FOLDERS_REGEX = /\/(assets\/(img|svg))\//g

export const getLocalizedImageSrc = (src: string, userLocale: EnumUserLocale) =>
  src.replace(LOCALIZED_FOLDERS_REGEX, `/$1/${userLocale}/`)

export interface ImageProps extends NextImageProps {
  src: string
}
export const Image = ({ src, ...props }: ImageProps) => {
  const userLocale = useUserLocale()

  return <NextImage src={getLocalizedImageSrc(src, userLocale)} {...props} />
}
