import { EnumAuthTrackingEvents } from 'modules/auth/lib/tracking/events'
import { EnumBankTrackingEvents } from 'modules/bank/lib/tracking/events'
import { EnumBillingTrackingEvents } from 'modules/billing/lib/tracking/events'
import { EnumCorporateTrackingEvents } from 'modules/corporate/lib/tracking/events'
import { EnumHomeTrackingEvents } from 'modules/home/lib/tracking/events'
import { EnumInvoicingTrackingEvents } from 'modules/invoicing/lib/tracking/events'
import { EnumOnboardingTrackingEvents } from 'modules/onboarding/lib/tracking/events'
import { EnumServicesTrackingEvents } from 'modules/services/lib/tracking/events'
import { EnumSettingsTrackingEvent } from 'modules/settings/lib/tracking/events'

export enum EnumCommonTrackingEvents {
  PAGEVIEW = 'Pageview',
}

export type TrackingEvent =
  | EnumCommonTrackingEvents
  | EnumAuthTrackingEvents
  | EnumInvoicingTrackingEvents
  | EnumBankTrackingEvents
  | EnumCorporateTrackingEvents
  | EnumServicesTrackingEvents
  | EnumOnboardingTrackingEvents
  | EnumHomeTrackingEvents
  | EnumBillingTrackingEvents
  | EnumSettingsTrackingEvent
