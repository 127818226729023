import {
  Button,
  ButtonProps,
} from 'modules/common/components/_ui/Button/Button'
import { useHideModalContext } from 'modules/common/contexts/HideModalContext'
import { useState } from 'react'

export interface CtaModalFooterProps {
  secondaryButtonText?: string
  onSecondaryClick?: () => unknown
  primaryButtonText: string
  primaryButtonProps?: Partial<
    Pick<
      ButtonProps,
      'variant' | 'color' | 'disabled' | 'icon' | 'iconPosition'
    >
  >
  onPrimaryClick?: () => unknown
  buttonsAlignment?: 'center' | 'stretch'
  hiddenOnError?: boolean
}

export const CtaModalFooter = ({
  secondaryButtonText,
  onSecondaryClick,
  primaryButtonText,
  primaryButtonProps: {
    variant = 'primary',
    color = 'default',
    disabled = false,
    icon,
    iconPosition,
  } = {},
  onPrimaryClick,
  buttonsAlignment = 'stretch',
  hiddenOnError = false,
}: CtaModalFooterProps) => {
  const [isLoading, setIsLoading] = useState(false)

  const { hideModal } = useHideModalContext()

  const buttonClassName =
    buttonsAlignment === 'stretch' ? 'min-w-full sm:min-w-0 flex-1' : ''

  return (
    <>
      {secondaryButtonText && (
        <Button
          variant="secondary"
          color="default"
          onClick={async () => {
            if (onSecondaryClick) {
              await onSecondaryClick()
            }
            hideModal()
          }}
          data-testid="secondary-modal-button"
          className={buttonClassName}
        >
          {secondaryButtonText}
        </Button>
      )}

      <Button
        variant={variant}
        color={color}
        disabled={disabled}
        icon={icon}
        iconPosition={iconPosition}
        onClick={async () => {
          setIsLoading(true)
          try {
            onPrimaryClick && (await onPrimaryClick())
            hideModal()
          } catch {
            hiddenOnError && hideModal()
          } finally {
            setIsLoading(false)
          }
        }}
        data-testid="primary-modal-button"
        className={buttonClassName}
        isLoading={isLoading}
      >
        {primaryButtonText}
      </Button>
    </>
  )
}
