import { mergeClasses } from '@blank/utilities'
import { useTranslation } from 'modules/common/hooks/useTranslation'
import { Trans } from 'next-i18next'
import { Illustration } from '../Illustrations/Illustration'
import { IconReload } from '../_icons/icons'
import { Button } from '../_ui/Button/Button'

export interface ErrorReloadSmallProps {
  onReload: () => void
  title?: string
  description?: string
  buttonLabel?: string
  className?: string
  withIcon?: boolean
  withTitle?: boolean
}

export const ErrorReloadSmall = ({
  onReload,
  title,
  description,
  buttonLabel,
  className,
  withIcon = true,
  withTitle = true,
}: ErrorReloadSmallProps) => {
  const { t } = useTranslation('common')

  return (
    <div
      className={mergeClasses(
        'flex h-full w-full flex-col items-center justify-center gap-y-6',
        className
      )}
    >
      {withIcon && <Illustration name="error" size="s" />}
      {withTitle && (
        <h2 className="text-center">
          {title || t('errors.reloadContent.title')}
        </h2>
      )}
      <Trans parent="p" className="text-center text-info-400">
        {description || t('errors.reloadContent.bodySmall')}
      </Trans>
      <Button
        color="default"
        variant="tertiary"
        onClick={onReload}
        className="flex items-center py-2"
        type="button"
        icon={<IconReload />}
      >
        {buttonLabel || t('errors.reloadContent.cta.retry')}
      </Button>
    </div>
  )
}
