import { AccountApi, EnumAccountSource, EnumDocumentStatus } from '@blank/api'
import { Row } from '@tanstack/react-table'
import { DocumentRow } from 'modules/invoicing/types/document'

const emptyLastSort = (a: any, b: any) => {
  if (!a && !b) {
    return 0
  }
  if (!a) {
    return 1
  }
  if (!b) {
    return -1
  }
}

export const alphaNumericEmptyLastSort = <T extends object>(
  rowA: Row<T>,
  rowB: Row<T>,
  columnId: string
) => {
  const a: string = rowA.getValue(columnId)
  const b: string = rowB.getValue(columnId)
  const emptySort = emptyLastSort(a, b)
  if (emptySort !== undefined) {
    return emptySort
  }
  return a.localeCompare(b)
}

export const numericEmptyLastSort = <T extends object>(
  rowA: Row<T>,
  rowB: Row<T>,
  columnId: string
) => {
  const a = rowA.getValue<number>(columnId),
    b = rowB.getValue<number>(columnId)
  const emptySort = emptyLastSort(a, b)
  if (emptySort !== undefined) {
    return emptySort
  }
  return a < b ? -1 : a > b ? 1 : 0
}

export const dateStringEmptyLastSort = <T extends object>(
  rowA: Row<T>,
  rowB: Row<T>,
  columnId: string
) => {
  const a = rowA.getValue<string>(columnId),
    b = rowB.getValue<string>(columnId)

  const emptySort = emptyLastSort(a, b)
  if (emptySort !== undefined) {
    return emptySort
  }
  const aVal = a.split('/').reverse().join(),
    bVal = b.split('/').reverse().join()
  return aVal < bVal ? -1 : aVal > bVal ? 1 : 0
}

const documentStatusOrder = [
  EnumDocumentStatus.ACCEPTED,
  EnumDocumentStatus.WAITING,
  EnumDocumentStatus.CANCELLED,
  EnumDocumentStatus.DRAFT,
  EnumDocumentStatus.SENT,
  EnumDocumentStatus.PAID,
]

export const documentStatusSort = (
  rowA: Row<DocumentRow>,
  rowB: Row<DocumentRow>,
  _columnId: string
) => {
  const a = rowA.getValue<EnumDocumentStatus>('status'),
    b = rowB.getValue<EnumDocumentStatus>('status')

  const emptySort = emptyLastSort(a, b)
  if (emptySort !== undefined) {
    return emptySort
  }
  const aVal = documentStatusOrder.indexOf(a),
    bVal = documentStatusOrder.indexOf(b)
  return aVal < bVal ? -1 : aVal > bVal ? 1 : 0
}

export const sortAccounts = (accounts: AccountApi[] = []): AccountApi[] =>
  accounts.sort((a, b) => {
    if (a.source === EnumAccountSource.INTERNAL) {
      return -1
    } else if (b.source === EnumAccountSource.INTERNAL) {
      return 1
    }
    return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
  })
