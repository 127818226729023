import { mergeClasses } from '@blank/utilities'
import { TabProps } from 'modules/common/hooks/useTabs'
import { PropsWithChildren } from 'react'
import { Button } from '../_ui/Button/Button'

interface Props extends TabProps {
  className?: string
  activeClassName?: string
  disabledClassName?: string
  dataTestId?: string
}
export const Tab = ({
  children,
  onClick,
  isActive,
  disabled,
  className,
  activeClassName = '',
  disabledClassName = '',
  href,
  title,
  dataTestId,
}: PropsWithChildren<Props>) => {
  return (
    <Button
      data-testid={dataTestId}
      className={mergeClasses(
        'cursor-pointer whitespace-nowrap border-b-2 border-info-100 py-2 text-lg leading-5 text-info-400 transition-colors',
        className,
        {
          'border-primary !text-primary': isActive,
          [activeClassName]: isActive,
          'cursor-default !text-info-200': disabled,
          [disabledClassName]: disabled,
        }
      )}
      variant="none"
      title={title}
      onClick={onClick}
      {...(href && {
        as: 'a',
        href,
      })}
    >
      {children}
    </Button>
  )
}
