import dayjs from 'dayjs'
import frDayjs from 'dayjs/locale/fr'
import itDayjs from 'dayjs/locale/it'
import { useEffect } from 'react'
import { EnumUserLocale } from '../types/locale'
import { useUserLocale } from './useUserLocale'

export const useInitDayjsLocale = () => {
  const locale = useUserLocale()

  useEffect(() => {
    switch (locale) {
      case EnumUserLocale.FR: {
        dayjs.locale(frDayjs)
        break
      }
      case EnumUserLocale.IT: {
        dayjs.locale(itDayjs)
        break
      }
    }
  }, [locale])
}
