import { IconPhone } from 'modules/common/components/_icons/icons'
import { BackgroundIcon } from 'modules/common/components/_ui/BackgroundIcon/BackgroundIcon'
import { ListItem } from 'modules/common/components/_ui/ListItem/ListItem'
import { useSupportContext } from 'modules/common/hooks/useSupportContext'
import { useTranslation } from 'modules/common/hooks/useTranslation'

interface Props {
  phoneSupport: string
}

export const PhoneTouchpointListItem = ({ phoneSupport }: Props) => {
  const { t } = useTranslation('common-components')

  const supportContext = useSupportContext()

  return (
    <ListItem
      titlePosition="top"
      title={phoneSupport}
      subtitle={t('supportModal.supportTouchpoints.phoneDetails', {
        context: supportContext,
      })}
      icon={
        <BackgroundIcon
          color="important"
          size="small"
          variant="light"
          icon={<IconPhone />}
        />
      }
    />
  )
}
