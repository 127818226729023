import { mergeClasses } from '@blank/utilities'
import { PropsWithChildren } from 'react'

interface Props {
  className?: string
}
export const ListItemsContainer = ({
  children,
  className,
}: PropsWithChildren<Props>) => {
  if (!children) {
    return null
  }
  return (
    <div
      className={mergeClasses(
        'flex flex-col divide-y divide-info-100 rounded-2xl border border-info-100 bg-white px-6',
        className
      )}
    >
      {children}
    </div>
  )
}
