import { EnumFiscalResidence } from '@blank/api'
import { CountryConfig, EnumHomeCountryCode } from '../../types'

export const BLANK_IT_COUNTRY_CONFIG: CountryConfig = {
  companyIdentificationNumberLength: 11,
  shouldChooseDomiciliationOnSignup: true,
  isBeta: true,
  canAddEnterpriseMandate: true,
  shouldDisplayUserFeedbackButton: true,
  canAddSponsorshipVoucherOnSignup: false,
  increaseTransferLimitTypeformId: 'v3ZysnUs',
  feedbackTypeformId: 'W7LXQDri',
  accountClosingTypeformId: 'kakQJ2BN',
  shouldDisplayAdditionalTouOnSelectOffer: true,
  fiscalResidenceOptions: [EnumFiscalResidence.ITA, EnumFiscalResidence.USA],
  homeCountryCode: EnumHomeCountryCode.ITA,
  invoicing: {
    canCreateEstimateDocument: false,
    canAddFiscalTaxesToDocument: true,
    canAddFiscalStampToDocument: true,
    shouldSelectVATExonerationCode: true,
  },
  shouldDisplayAverageBalanceDocuments: true,
  shouldBuildMicroEnterpriseCreationURLWithParams: true,
  whatsAppSupportNumber: '+393513950827',
  canDepositCheck: false,
  shouldCustomizeCardFraudInsuranceRedirectionUrl: false,
  fileComplaintTypeformId: 'KoYlR5wq',
}
