import { CorporateLifecycleApi, EnumLifecycleStep } from '@blank/api'

export const getIsKycComplete = (lifeycle?: CorporateLifecycleApi | null) => {
  if (!lifeycle) {
    return false
  }
  return lifeycle?.KYCStepStatus
    ? Object.values(lifeycle.KYCStepStatus).every((stepStatus) => {
        return stepStatus === EnumLifecycleStep.DONE
      })
    : false
}
