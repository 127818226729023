import { mergeClasses } from '@blank/utilities'
import { IconCopyText } from '../../_icons/icons'
import { Button, ButtonProps } from '../../_ui/Button/Button'

interface Props {
  onClick?: ButtonProps['onClick']
  isCopied: boolean
  className?: string
  label?: string
}

export const CopyTextTertiaryButton = ({
  onClick,
  isCopied,
  className,
  label,
}: Props) => (
  <Button
    className={mergeClasses(
      'font-bold',
      { 'text-success': isCopied, 'text-info-600': !isCopied },
      className
    )}
    variant="none"
    type="button"
    onClick={onClick}
    icon={<IconCopyText />}
  >
    {label}
  </Button>
)
