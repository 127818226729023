import { CopyTextPrimaryButton } from './CopyTextPrimaryButton/CopyTextPrimaryButton'
import { CopyTextTertiaryButton } from './CopyTextTertiaryButton/CopyTextTertiaryButton'
import { useCopyText } from './useCopyText'

type Props = {
  textToCopy: string
  onCopy?: () => void
  className?: string
} & (
  | { variant: 'primary'; label?: never }
  | { variant?: 'tertiary'; label?: string }
)

export const CopyText = ({
  textToCopy,
  onCopy,
  className,
  label,
  variant = 'tertiary',
}: Props) => {
  const { isCopied, onClick } = useCopyText({ textToCopy, onCopy })

  switch (variant) {
    case 'primary': {
      return (
        <CopyTextPrimaryButton
          isCopied={isCopied}
          onClick={onClick}
          className={className}
        />
      )
    }
    case 'tertiary': {
      return (
        <CopyTextTertiaryButton
          isCopied={isCopied}
          onClick={onClick}
          className={className}
          label={label}
        />
      )
    }
  }
}
