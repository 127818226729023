import { useCorporateQuery } from 'modules/corporate/queries/useCorporateQuery'
import { Corporate } from 'modules/corporate/types/corporates'
import { useCallback } from 'react'
import { QueryOptions } from '../types/queries'
import { useAuthenticatedUserQuery } from './useAuthenticatedUserQuery'

export const useAuthenticatedUserCorporateQuery = (
  queryOptions?: QueryOptions<Corporate, Error>
) => {
  const {
    data: user,
    isLoading: isUserLoading,
    isError: isUserError,
    isSuccess: isUserSuccess,
    refetch: refetchUser,
  } = useAuthenticatedUserQuery()

  const {
    data: corporate,
    isLoading: isCorporateLoading,
    isError: isCorporateError,
    isSuccess: isCorporateSuccess,
    refetch: refetchCorporate,
  } = useCorporateQuery(user?.corporateId, queryOptions)

  const errorRefetch = useCallback(() => {
    if (isUserError) {
      refetchUser()
    }
    if (isCorporateError) {
      refetchCorporate()
    }
  }, [isUserError, isCorporateError, refetchUser, refetchCorporate])

  return {
    data: { user, corporate },
    isLoading: isUserLoading || isCorporateLoading,
    isError: isUserError || isCorporateError,
    isSuccess: isUserSuccess && isCorporateSuccess,
    errorRefetch,
  }
}
