import { mergeClasses } from '@blank/utilities'
import { PropsWithChildren, UIEventHandler } from 'react'

interface Props {
  className?: string
  onScroll: UIEventHandler<HTMLDivElement>
}

export const ModalBody = ({
  children,
  className,
  onScroll,
}: PropsWithChildren<Props>) => {
  return (
    <div onScroll={onScroll} className="w-full min-w-min flex-1 overflow-auto">
      <div
        className={mergeClasses('m-auto flex flex-col px-10 py-8', className)}
      >
        {children}
      </div>
    </div>
  )
}
