import { IconCheck, IconThreeDots } from '../../_icons/icons'

export enum EnumVerticalStepStatus {
  COMPLETED = 'COMPLETED',
  INFO = 'INFO',
  ONGOING = 'ONGOING',
  UPCOMING = 'UPCOMING',
}

interface Props {
  connectorTheme: `border-${string}-${number}`
  descriptionTheme: `text-${string}-${number}`
  iconTheme: `bg-${string}-${number}`
  titleTheme: `text-${string}-${number}`
}

export const VERTICAL_PROGRESS_STEPPER_THEME: Record<
  EnumVerticalStepStatus,
  Props
> = {
  [EnumVerticalStepStatus.COMPLETED]: {
    connectorTheme: 'border-success-500',
    descriptionTheme: 'text-info-600',
    iconTheme: 'bg-success-500',
    titleTheme: 'text-success-500',
  },
  [EnumVerticalStepStatus.INFO]: {
    connectorTheme: 'border-info-600',
    descriptionTheme: 'text-info-600',
    iconTheme: 'bg-info-600',
    titleTheme: 'text-info-600',
  },
  [EnumVerticalStepStatus.ONGOING]: {
    connectorTheme: 'border-primary-500',
    descriptionTheme: 'text-info-600',
    iconTheme: 'bg-primary-500',
    titleTheme: 'text-primary-500',
  },
  [EnumVerticalStepStatus.UPCOMING]: {
    connectorTheme: 'border-info-400',
    descriptionTheme: 'text-info-400',
    iconTheme: 'bg-info-400',
    titleTheme: 'text-info-400',
  },
}

export const VERTICAL_PROGRESS_STEPPER_DEFAULT_ICONS: Record<
  EnumVerticalStepStatus,
  JSX.Element
> = {
  [EnumVerticalStepStatus.COMPLETED]: <IconCheck />,
  [EnumVerticalStepStatus.INFO]: <IconThreeDots />,
  [EnumVerticalStepStatus.ONGOING]: <IconThreeDots />,
  [EnumVerticalStepStatus.UPCOMING]: <IconThreeDots />,
}
