import { CorporateOfferApi } from '@blank/api'
import { apiClient } from 'modules/common/http/apiClient'
import { getAuthorizationHeader } from 'modules/common/lib/api/getAuthorizationHeader'

interface Props {
  corporateId: string
  accessToken: string
}

export const fetchCorporateOffer = async ({
  corporateId,
  accessToken,
}: Props) => {
  const apiUrl = `/v1/users/corporates/${corporateId}/offers`

  const { data } = await apiClient.get<CorporateOfferApi>(apiUrl, {
    headers: getAuthorizationHeader(accessToken),
  })

  return data
}
