import { CorporateInformationApi, EnumUserCountry } from '@blank/api'

export const getCompanyIdentificationNumber = (
  corporateInformation: CorporateInformationApi
) => {
  if (corporateInformation.informationType === EnumUserCountry.ITA) {
    return corporateInformation.partitaIva
  } else {
    return corporateInformation.siren
  }
}
